import {FieldRequirement, HashSchema, REQUIRED_OBJECT, REQUIRED_STRING} from '../lib/ValidateData';
import {ZED_WEB_DATA_SCHEMA, ZedWebData} from './ZedWebData';
import {StaticContext} from './static-context/StaticContext';

export enum WebContentPosition {
  Before = 'before',
  After = 'after',
  Inside = 'inside',
  Replace = 'replace',
  Modal = 'modal',
}

export interface ShowWebContentOptions {
  // Content identifier from the Zaius App
  contentId: string;

  target: {
    // Document query selector for placing inline
    selector: string;
    // Where to place after locating the target
    position: WebContentPosition;
  };
  displayAlways?: boolean;
  onSuccess?: () => void;
  onError?: (message: string) => void;
  afterSubmit?: () => void;

  renderVariables?: Record<string, unknown>;
  previewContent?: ZedWebData;
  previewContext?: StaticContext;
}

const OPTIONAL_ZED_WEB_DATA_SCHEMA: HashSchema<ZedWebData> = {...ZED_WEB_DATA_SCHEMA, type: FieldRequirement.Object};

export const WEB_CONTENT_OPTIONS_SCHEMA: HashSchema<ShowWebContentOptions> = {
  type: REQUIRED_OBJECT,
  properties: {
    contentId: REQUIRED_STRING,
    target: {
      type: REQUIRED_OBJECT,
      properties: {
        position: {
          type: REQUIRED_STRING,
          oneOf: [WebContentPosition.Before, WebContentPosition.After, WebContentPosition.Inside, WebContentPosition.Replace, WebContentPosition.Modal]
        },
        selector: FieldRequirement.String,
      }
    },
    onSuccess: FieldRequirement.Function,
    onError: FieldRequirement.Function,
    afterSubmit: FieldRequirement.Function,
    renderVariables: FieldRequirement.Object,
    previewContent: OPTIONAL_ZED_WEB_DATA_SCHEMA
  }
};
