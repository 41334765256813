import {PredicateEvaluator, lower, WebContentPredicateOperators} from './PredicateCommon';

export class ComparatorPredicateEvaluator implements PredicateEvaluator {
  constructor(
    public operator: WebContentPredicateOperators,
    private field: string,
    private value: string
  ) {}

  public evaluate(values: {[key: string]: string}) {
    let value = lower(values[this.field]);
    if (this.field === 'page_url' && this.operator === WebContentPredicateOperators.Contains) {
      value = lower(values['full_page_url']);
    }
    switch (this.operator) {
      case WebContentPredicateOperators.Contains:
        return value.indexOf(this.lowerValue) !== -1;
      case WebContentPredicateOperators.DoesNotContain:
        return value.indexOf(this.lowerValue) === -1;
      case WebContentPredicateOperators.Equals:
        return value === this.lowerValue;
      case WebContentPredicateOperators.DoesNotEqual:
        return value !== this.lowerValue;
    }
    return false;
  }

  private get lowerValue() {
    if (this.field === 'page_url') {
      return lower(this.value)
               .replace(/^(https?:\/\/)/, '')
               .replace(/\/(?:index\.\w{1,5})?$/, '');
    }

    return lower(this.value);
  }
}
