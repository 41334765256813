import {MobileUtilities} from '../lib/MobileUtilities';
import {WebContentUtilities} from '../lib/WebContentUtilities';
import {disablePageScrolling} from './Scrolling';
import {ZedWebData} from './ZedWebData';
import {utils} from '../utils';

// modals get an extra 12px height/width for the close button
// Only change this if the close button size, position, or behavior in zed has changed
export const CLOSE_BUTTON_PADDING = 12;

const BACKDROP_STYLES = `
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 2000000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100%;
  background-color: #333;
  background-color: rgba(70,70,70,.75);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

const PARENT_CONTAINER_STYLES = `
  flex-grow: 0;
  margin: 0;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  max-height: fill-available;
`;

const MODAL_CSS = `
  @keyframes zaius-modal-in {
    0% {
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes zaius-modal-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

let addedCSS = false;

export class ModalMaker {
  private backdrop: HTMLDivElement = document.createElement('DIV') as HTMLDivElement;
  private enablePageScrolling: (() => void) | null = null;
  private disabledScrollTimeout?: NodeJS.Timeout;
  private activeElement: Element | null = null;
  private container!: HTMLDivElement;
  private heightInterval: NodeJS.Timer | null = null;

  constructor(private data: ZedWebData, private readonly closeCallback: () => void) {
    if (!data.config.modal) {
      throw new Error('Missing modal config');
    }

    if (!addedCSS) {
      const style = document.createElement('STYLE') as HTMLStyleElement;
      style.appendChild(document.createTextNode(MODAL_CSS));
      document.head.appendChild(style);
      addedCSS = true;
    }
  }

  public destroy() {
    this.backdrop.remove();
    if (this.heightInterval) {
      clearInterval(this.heightInterval);
    }
  }

  public beforeShow() {
    this.disabledScrollTimeout && clearTimeout(this.disabledScrollTimeout);
    this.disabledScrollTimeout = undefined;
  }

  public showModal(iframe: HTMLIFrameElement) {
    iframe.style.display = '';
    iframe.style.maxWidth = '100vw';
    iframe.style.animation = 'zaius-modal-in 300ms ease-out forwards';
    this.backdrop.style.display = 'flex';
    // fix zoom on mobile, wait for iframe to populate
    if (MobileUtilities.isMobileIOS()) {
      const elements = Array.prototype.slice.call(iframe.contentDocument!.body.getElementsByTagName('input'));
      for (const e of elements) {
        e.style.fontSize = 'initial';
      }
    }

    // In case content loads late or we miss a layout change, periodically update the height
    if (!SDK_TEST_MODE) {
      this.heightInterval = setInterval(() => this.updateHeight(iframe), 200);
    }
  }

  public addModal(iframe: HTMLIFrameElement) {
    const {backdropColor, hasCloseButton} = this.data.config.modal!;
    // configure the backdrop
    this.backdrop.setAttribute('style', BACKDROP_STYLES);
    if (backdropColor) {
      this.backdrop.style.background = backdropColor;
    }
    if (hasCloseButton) {
      this.backdrop.onclick = null;
    } else {
      this.backdrop.onclick = this.hideModal;
    }

    this.container = document.createElement('DIV') as HTMLDivElement;
    this.container.setAttribute('style', PARENT_CONTAINER_STYLES);
    this.container.setAttribute('id', 'zmodal_parent_div');
    this.container.appendChild(iframe);
    this.backdrop.appendChild(this.container);
    document.body.appendChild(this.backdrop);
    this.disableScrolling();
    this.activeElement = document.activeElement;

    iframe.style.marginTop = ((CLOSE_BUTTON_PADDING / 2) * -1) + 'px';
  }

  public hideModal = (): Promise<void> => {
    this.closeCallback();
    return new Promise((resolve) => {
      this.backdrop.style.animation = 'zaius-modal-out 300ms ease-in forwards';
      setTimeout(() => {
        this.backdrop.style.display = 'none';
        this.backdrop.style.animation = '';
        this.backdrop.remove();
        this.enableScrolling();
        this.moveFocusBackToPage();
        resolve();
      }, 300);
    });
  };

  public moveFocusBackToPage = () => {
    if (this.activeElement) {
      const activeElement = this.activeElement as HTMLElement;
      if (activeElement.focus) {
        activeElement.focus();
      }
    }
  };

  public updateSize(iframe: HTMLIFrameElement, width: number, _height: number) {
    if (iframe.contentDocument!.body) {
      width = Math.max(width, this.data.config.modal!.width || 600);

      this.updateWidth(iframe, width);
      this.updateHeight(iframe);
      this.toggleCloseButton(iframe);
    }
  }

  /**
   * We only want to inlcude padding for the close button when we the close button is outside the container
   */
  private get closeButtonPaddingRight() {
    if (!this.data.config.modal) {
      return CLOSE_BUTTON_PADDING;
    }
    const {hasCloseButton, closeButtonInside} = this.data.config.modal;
    return hasCloseButton && !closeButtonInside ? CLOSE_BUTTON_PADDING : 0;
  }

  private toggleCloseButton(iframe: HTMLIFrameElement) {
    const {hasCloseButton} = this.data.config.modal!;
    const closeButtonDiv = iframe.contentDocument!.body.querySelector('#modal-close.close-button')! as HTMLDivElement;
    const mobile = WebContentUtilities.viewportWidth() <= iframe.getBoundingClientRect().width;

    if (mobile) {
      closeButtonDiv.style.display = 'block';
    }

    if (!mobile && !hasCloseButton) {
      closeButtonDiv.style.display = 'none';
    }
  }

  private updateHeight(iframe: HTMLIFrameElement) {
    // Calculate the height for modals rather than using the provided height
    const height = iframe.contentDocument?.body?.querySelector('.container-wrapper')?.scrollHeight!;
    if (height) {
      const maxHeight = (window.innerHeight || document.documentElement.clientHeight);
      iframe.style.height = height + CLOSE_BUTTON_PADDING + 'px';
      this.container.style.height = iframe.style.height;
      this.container.style.maxHeight = maxHeight + 'px';
    }
  }

  private updateWidth(iframe: HTMLIFrameElement, width: number) {
    iframe.style.width = width + this.closeButtonPaddingRight + 'px';
    if (WebContentUtilities.viewportWidth() <= iframe.getBoundingClientRect().width) {
      iframe.contentDocument!.body.classList.add('mobile');
    } else {
      iframe.contentDocument!.body.classList.remove('mobile');
    }

    this.container.style.width = iframe.style.width;
  }

  private disableScrolling() {
    this.enablePageScrolling ||= disablePageScrolling();
    this.disabledScrollTimeout = setTimeout(() => {
      this.enableScrolling();
      utils.console.warn(`[Web Content] [Modal] Scroll re-enabled after timeout`);
    }, 2000);
  }

  private enableScrolling() {
    this.enablePageScrolling && this.enablePageScrolling();
    this.enablePageScrolling = null;
  }
}
