import {RecentlyViewedProducts, ProductRecommendationSources, ProductRecommendationSourceType} from '../../lib/RecentlyViewedProducts';
import {validateData} from '../../lib/ValidateData';
import tracker from '../../tracker';
import {utils} from '../../utils';
import {getJson, RequestOptions} from '../httpRequest';
import {
  PRODUCT_RECOMMENDATION_SCHEMA, ProductRecommendationType, ProductRecommendation
} from './ProductRecomendation';

type CustomerIdentifierNames = 'vuid' | 'email' | 'customer_id' | 'user_id';
type CustomerIdentifier = StrictUnionize<{ [key in CustomerIdentifierNames]: string }>;

interface ProductRecommendationParams {
  identifier?: CustomerIdentifier;
  productIds?: string[];
  limit?: number;
  type?: ProductRecommendationSourceType;
  source?: ProductRecommendationSources;
  filter?: {};
}

export class ProductRecommendationService {
  private static _recommendationsUrl: string;

  public static get recommendationsUrl(): string {
    return this._recommendationsUrl ||= `${tracker.publicApiUrl}/recommendations/products`;
  }

  public static getTypeAndProductIds(source: ProductRecommendationSources, type?: ProductRecommendationSourceType, productIds: string[] = []): {type: ProductRecommendationType, productIds: string[]} {
    if (source === ProductRecommendationSources.ZAIUS_RECOMMENDATIONS) {
      if (productIds.length === 0) {
        switch (type) {
          case ProductRecommendationSourceType.PRODUCTS_ON_PAGE:
            productIds = RecentlyViewedProducts.getIdsOnPage();
            break;
          case ProductRecommendationSourceType.RECENT_BROWSE_HISTORY:
            productIds = RecentlyViewedProducts.getIds();
            break;
        }
      }
      return {
        type: productIds.length > 0 ? ProductRecommendationType.CONTEXTUAL : ProductRecommendationType.PERSONAL,
        productIds
      };
    } else if (source === ProductRecommendationSources.BEST_SELLERS) {
      return {type: ProductRecommendationType.BEST_SELLERS, productIds};
    }
    return {type: ProductRecommendationType.PERSONAL, productIds};
  }

  public static fetch({identifier, productIds, limit, type, source = ProductRecommendationSources.ZAIUS_RECOMMENDATIONS, filter}: ProductRecommendationParams): Promise<ProductRecommendation[]> {
    const params: {[key: string]: any} = {};
    let url: string = this.recommendationsUrl;

    const {type: recommendationType, productIds: productIdsToUse} = this.getTypeAndProductIds(source, type, productIds);

    // The only way to get best sellers out is to provide the API with no identifier or contextual recommendations to use.
    if (recommendationType !== ProductRecommendationType.BEST_SELLERS) {
      if (productIdsToUse.length > 0) {
        params.product_ids = productIdsToUse.join(',');
      }
      if (recommendationType) {
        params.type = recommendationType;
      }
      if (identifier) {
        Object.assign(params, identifier);
      } else {
        params.vuid = tracker.vuid();
      }
    }

    if (limit) {
      params.limit = String(limit);
    }

    if (filter) {
      params.criteria = filter;
    }

    const queryParameters = utils.queryStringify(params, '', ['product_ids']);

    if (queryParameters) {
      url = url + '?' + queryParameters;
    }

    const requestOptions: RequestOptions = {
      url,
      headers: [{
        key: 'x-api-key',
        value: tracker.trackerId as any
      }]
    };

    return new Promise(((resolve, reject) => {
      getJson(requestOptions, (recommendations) => {
        try {
          if (validateData(recommendations, PRODUCT_RECOMMENDATION_SCHEMA)) {
            resolve(recommendations);
          }
        } catch (error) {
          reject(error);
        }
      });
    }));
  }
}
