/*!
  * domready (c) Dustin Diaz 2012 - License MIT
  * v0.3.0 (https://github.com/ded/domready/tree/v0.3.0)
  */
let fns = [], fn, f = false
  , doc = document
  , testEl = doc.documentElement
  , hack = testEl.doScroll
  , domContentLoaded = 'DOMContentLoaded'
  , addEventListener = 'addEventListener'
  , onreadystatechange = 'onreadystatechange'
  , readyState = 'readyState'
  , loadedRgx = hack ? /^loaded|^c/ : /^loaded|c/
  , loaded = loadedRgx.test(doc[readyState])

function flush(f) {
  loaded = 1
  while (f = fns.shift()) f()
}

doc[addEventListener] && doc[addEventListener](domContentLoaded, fn = function () {
  doc.removeEventListener(domContentLoaded, fn, f)
  flush(null)
}, f)


hack && doc.attachEvent(onreadystatechange, fn = function () {
  if (/^c/.test(doc[readyState])) {
    doc.detachEvent(onreadystatechange, fn)
    flush(null)
  }
})

const ready = hack ?
  function (fn) {
    window.self != window.top ?
      loaded ? fn() : fns.push(fn) :
      function () {
        try {
          testEl.doScroll('left')
        } catch (e) {
          return setTimeout(function() { ready(fn) }, 50)
        }
        fn()
      }()
  } :
  function (fn) {
    loaded ? fn() : fns.push(fn)
  };

export default ready;
