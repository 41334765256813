import {timeToMilliseconds} from '../../lib/TimeToMilliseconds';
import {TimerTriggerConfig} from '../WebTriggerData';
import {Trigger, TriggerHandler} from './Trigger';

export class TimerTrigger extends Trigger<TimerTriggerConfig> {
  private startTime: number = new Date().getTime();

  public static getInstance(): TimerTrigger {
    return this.instance || (this.instance = new TimerTrigger());
  }

  protected constructor() {
    super();
    window.setInterval(() => {
      this.forEach(this.evaluate);
    }, 250);
  }

  public reset(): void {
    this.startTime = new Date().getTime();
  }

  private evaluate = (definition: TimerTriggerConfig, handler: TriggerHandler): void => {
    if (definition.enabled) {
      const ms = timeToMilliseconds(definition.time, definition.timeUnit);
      if (new Date().getTime() - this.startTime >= ms) {
        handler();
      }
    }
  };
}
