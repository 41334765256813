import {FieldRequirement, HashSchema} from '../../lib/ValidateData';

export interface StaticContext {
  zaius?: {
    account: {
      site_url: string,
      company_name: string,
      company_address: string
    }
  };
}

export const STATIC_CONTEXT_SCHEMA: HashSchema<StaticContext> = {
  type: FieldRequirement.Object,
  properties: {
    zaius: {
      type: FieldRequirement.Object,
      properties: {
        account: {
          type: FieldRequirement.Object,
          properties: {
            site_url: {
              type: FieldRequirement.String,
            },
            company_name: {
              type: FieldRequirement.String,
            },
            company_address: {
              type: FieldRequirement.String,
            },
          }
        },
      }
    }
  }
};
