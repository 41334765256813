import {
  FieldRequirement, HashSchema, REQUIRED_NUMBER, REQUIRED_OBJECT, REQUIRED_STRING
} from '../lib/ValidateData';
import {WebContentPosition} from './ShowWebContentOptions';
import {WebContentPredicate, WebContentPredicateOperators} from './constraints/PredicateCommon';

export enum TimeUnit {
  Weeks = 'weeks',
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
  Seconds = 'seconds'
}

export interface DisplayFrequencyConfig {
  enabled: boolean;
  count: number;
  time: number;
  timeUnit: TimeUnit;
}

export interface DisplayLimitConfig {
  enabled: boolean;
  limit: number;
}

export interface TimerTriggerConfig {
  enabled: boolean;
  time: number;
  timeUnit: TimeUnit;
}

export interface ScrollTriggerConfig {
  enabled: boolean;
  percent: number;
}

export interface TriggerConfig {
  timeSpent?: TimerTriggerConfig;
  pageScroll?: ScrollTriggerConfig;
  exitIntent?: boolean;
  pageLoad?: boolean;
}

export interface ConstraintsConfig {
  displayAlways?: boolean;
  displayLimit?: DisplayLimitConfig;
  displayFrequency?: DisplayFrequencyConfig;
  showOnMobile?: boolean;
}

export interface ZedWebDefinition {
  version?: number;
  constraints: ConstraintsConfig;
  triggers: TriggerConfig;
  display: {
    predicate: WebContentPredicate,
    contentPosition: WebContentPosition;
    selector: string;
  };
  realtime_segments?: string[];
}

export interface ZedWebCampaignDefinitions {
  [key: string]: ZedWebDefinition;
}

const DISPLAY_LIMIT: HashSchema<DisplayLimitConfig> = {
  type: FieldRequirement.Object,
  properties: {
    enabled: FieldRequirement.Boolean,
    limit: REQUIRED_NUMBER,
  }
};

const DISPLAY_FREQUENCY: HashSchema<DisplayFrequencyConfig> = {
  type: FieldRequirement.Object,
  properties: {
    enabled: FieldRequirement.Boolean,
    count: REQUIRED_NUMBER,
    time: REQUIRED_NUMBER,
    timeUnit: {
      type: REQUIRED_STRING,
      oneOf: Object.values(TimeUnit)
    },
  }
};

const TRIGGERS: HashSchema<TriggerConfig> = {
  type: FieldRequirement.Object,
  properties: {
    timeSpent: {
      type: FieldRequirement.Object,
      properties: {
        enabled: FieldRequirement.Boolean,
        time: REQUIRED_NUMBER,
        timeUnit: {
          type: REQUIRED_STRING,
          oneOf: Object.values(TimeUnit)
        }
      }
    },
    pageScroll: {
      type: FieldRequirement.Object,
      properties: {
        percent: REQUIRED_NUMBER
      }
    },
    exitIntent: FieldRequirement.Boolean,
    pageLoad: FieldRequirement.Boolean
  }
};

export const WEB_CONTENT_PREDICATE: HashSchema<WebContentPredicate> = {
  type: REQUIRED_OBJECT,
  properties: {
    operator: {
      type: REQUIRED_STRING,
      oneOf: [
        WebContentPredicateOperators.And,
        WebContentPredicateOperators.Or,
        WebContentPredicateOperators.Contains,
        WebContentPredicateOperators.Equals,
        WebContentPredicateOperators.DoesNotContain,
        WebContentPredicateOperators.DoesNotEqual
      ],
    },
    field: FieldRequirement.String,
    value: FieldRequirement.String,
  }
};

WEB_CONTENT_PREDICATE.properties!.predicates = {
  type: FieldRequirement.Array,
  all: WEB_CONTENT_PREDICATE
};

export const ZED_WEB_DEFINITION_SCHEMA: HashSchema<ZedWebDefinition> = {
  type: REQUIRED_OBJECT,
  properties: {
    version: FieldRequirement.Number,
    display: {
      type: REQUIRED_OBJECT,
      properties: {
        predicate: WEB_CONTENT_PREDICATE,
        contentPosition: {
          type: FieldRequirement.String,
          oneOf: Object.values(WebContentPosition)
        },
        selector: FieldRequirement.String
      }
    },
    constraints: {
      type: REQUIRED_OBJECT,
      properties: {
        displayLimit: DISPLAY_LIMIT,
        displayFrequency: DISPLAY_FREQUENCY,
        triggers: TRIGGERS,
        showOnMobile: FieldRequirement.Boolean
      }
    }
  }
};
