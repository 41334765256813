import {utils} from '../utils';

export const RECENT_EVENTS_KEY = 'zRecentEvents';

export const CREATED_AT_FIELD = '__zRCTS'; // internal recent event timestamp field

const UPDATE_STORAGE_TRIES = 3;

export interface RecentEventStored {
  type: string;
  action?: string;
  [CREATED_AT_FIELD]: number;
  [key: string]: any;
}

interface RecentEventsStorageProps {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
}

export const RecentEventsStorage = {
  get storage(): RecentEventsStorageProps {
    return isLocalStorageAvailable() ? window.localStorage : NullStorage;
  },

  /* public */
  readAll(key: string): RecentEventStored[] {
    try {
      const recentEventsStr = this.storage.getItem(RECENT_EVENTS_KEY);
      const recentEventsObject = JSON.parse(recentEventsStr || '{}');
      return Array.isArray(recentEventsObject[key]) ? recentEventsObject[key] : [];
    } catch (e) {
      utils.console.error(`Error while reading recent events from storage: ${e.message}`);
      return [];
    }
  },

  /* public */
  update(key: string, recentEvents: RecentEventStored[]) {
    for (let i = 0; i < UPDATE_STORAGE_TRIES; i++) {
      try {
        this.storage.setItem(RECENT_EVENTS_KEY, JSON.stringify(
          {
            [key]:
            recentEvents.slice(i) // Try to handle the quota exceeded error in a smart way
          }
        ));
        return;
      } catch (e) {
        utils.console.error(`Error while updating recent events in storage: ${e.message}`);
      }
    }
  }
};

const NullStorage: RecentEventsStorageProps = {
  getItem: (_key: string): string | null => {
    return null;
  },
  setItem: (_key: string, _value: string): void => {
    // do nothing
  }
};

function isLocalStorageAvailable(): boolean {
  return typeof window.localStorage !== 'undefined';
}
