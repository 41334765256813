import {REQUIRED_OBJECT, validateData, HashSchema, FieldRequirement} from '../lib/ValidateData';
import {tracker} from '../tracker';
import {utils} from '../utils';
import {ZaiusSDK} from '../Zaius';
import {pageMatchesPredicate} from './constraints/PageDisplayConstraints';
import {getJson} from './httpRequest';
import {toaster} from './Toaster';
import {WebContent} from './WebContent';
import {ZED_WEB_DEFINITION_SCHEMA, ZedWebDefinition} from './WebTriggerData';
import {ZED_WEB_DATA_SCHEMA, ZedWebData} from './ZedWebData';
import {STATIC_CONTEXT_SCHEMA, StaticContext} from './static-context/StaticContext';

export interface WebPreviewData {
  version?: number;
  content: ZedWebData;
  rules: ZedWebDefinition;
  static_context?: StaticContext;
}

const WEB_PREVIEW_DATA_SCHEMA: HashSchema<WebPreviewData> = {
  type: REQUIRED_OBJECT,
  properties: {
    version: FieldRequirement.Number,
    rules: ZED_WEB_DEFINITION_SCHEMA,
    content: ZED_WEB_DATA_SCHEMA,
    static_context: STATIC_CONTEXT_SCHEMA
  }
};

export const WebPreview = {
  getPreview(previewTrackerId: string | null): Promise<WebPreviewData> {
    const {config, trackerId} = tracker as ZaiusSDK;
    const identifier = WebContent.previewContentIdentifier();
    const url = `${config.webContent.source}${previewTrackerId || trackerId}/${identifier}.json`;

    if (!previewTrackerId) {
      WebPreview.displayWarning(
        'The site being previewed without tracker_id specified in url, so site tracker_id is used.'
      );
    } else if (previewTrackerId !== trackerId) {
      WebPreview.displayWarning(
        'The site being previewed is configured for a different account than the web campaign. Update your campaign or integration before going live.'
      );
    }

    return new Promise((resolve, reject) => {
      const error = (message: string) => {
        reject();
        WebPreview.displayError(message);
      };

      getJson({
        url,
        headers: [{
          key: 'Cache-Control',
          value: 'no-cache'
        }]
      }, (preview: unknown) => {
        try {
          if (validateData(preview, WEB_PREVIEW_DATA_SCHEMA)) {
            // previews ignore all constraints, except show on mobile
            preview.rules.constraints = {showOnMobile: preview.rules.constraints.showOnMobile};

            // ensure the page rules are valid for this page
            if (!pageMatchesPredicate(preview.rules.display.predicate)) {
                error('The URL for this page does not match any of your selected page targets, or has been excluded.');
            }

            toaster.info('<b>Previewing Web Content</b> - Web events and subscriptions will not be sent to Zaius during a preview.');

            resolve(preview);
          }
        } catch (err) {
          error('The preview data is not valid. This preview may have expired.');
        }
      }, (reason) => error(`${reason} - This preview may have expired.`));
    });
  },
  displayError(message: string) {
    toaster.error(message);
    WebPreview.logMessage(message);
  },
  displayWarning(message: string) {
    toaster.warning(message);
    WebPreview.logMessage(message);
  },
  logMessage(message: string) {
    utils.console.log(`Web Preview - ${message}`);
  }
};
