/**
 * Debounce decorator
 * usage:
 *   @debounce(400, 2000)
 *   public debouncedThing() { ... }
 * @param delay minimum delay until target should be called
 * @param limit maximum delay until target is called
 */
export function debounce(delay: number, limit: number = 0) {
  return (target: any, key: string, descriptor: PropertyDescriptor) => {
    if (descriptor === undefined) {
      descriptor = Object.getOwnPropertyDescriptor(target, key)!;
    }

    descriptor.value = debounced(delay, limit)(descriptor.value);

    return descriptor;
  };
}

/**
 * Debounce
 * usage:
 *   debounced(400, 2000)(function() { ... })
 * @param delay minimum delay until target should be called
 * @param limit maximum delay until target is called
 */
export function debounced(delay: number, limit: number = 0) {
  let timer: number;
  let start: number | null = null;

  return (callback: (...args: any[]) => any) => {
    return function(...args: any[]) {
      clearTimeout(timer);
      if (start === null) {
        start = new Date().getTime();
      }
      const elapsed = new Date().getTime() - start;

      // @ts-ignore
      const _this = this;
      timer = window.setTimeout(() => {
        start = null;
        callback.apply(_this, args);
      }, limit > 0 && elapsed + delay > limit ? Math.max(limit - elapsed, 0) : delay);
    };
  };
}
