import {Trigger, TriggerHandler} from './Trigger';

export interface ExitIntentTriggerConfig {
  enabled: boolean;
}

// cross-browser options for visibility change and document hidden APIs
let hiddenProp: string;
let visibilityChange: string;
if (typeof document.hidden !== 'undefined') {
  hiddenProp = 'hidden';
  visibilityChange = 'visibilitychange';
} else {
  // @ts-ignore
  if (typeof document.msHidden !== 'undefined') {
    hiddenProp = 'msHidden';
    visibilityChange = 'msvisibilitychange';
    // @ts-ignore
  } else if (typeof document.webkitHidden !== 'undefined') {
    hiddenProp = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }
}

export class ExitIntentTrigger extends Trigger<ExitIntentTriggerConfig> {
  public static getInstance(): ExitIntentTrigger {
    return this.instance || (this.instance = new ExitIntentTrigger());
  }

  protected constructor() {
    super();
    if (typeof document.addEventListener === 'undefined') {
      return;
    }

    // trigger when mouse leaves the window
    document.addEventListener('mouseout', this.onMouseOut, {passive: true});

    // trigger when changing tabs
    if (visibilityChange && hiddenProp) {
      document.addEventListener(visibilityChange, () => {
        if ((document as any)[hiddenProp]) {
          this.forEach(this.trigger);
        }
      });
    }

    // ideally we'd also trigger when the window loses focus (e.g. tabbing to another application)
    // but that is unreliable as giving focus to an iframe in the page would also fire the trigger
  }

  private onMouseOut = (e: MouseEvent) => {
    const height = (window.innerHeight || document.documentElement.clientHeight);
    const width = (window.innerWidth || document.documentElement.clientWidth);

    if (e.clientY <= 0 || e.clientX <= 0 || (e.clientX >= width || e.clientY >= height)) {
      this.forEach(this.trigger);
    }
  };

  private trigger = (definition: ExitIntentTriggerConfig, handler: TriggerHandler): void => {
    if (definition.enabled) {
      handler();
    }
  };
}
