import {utils} from '../../utils';
import {timeToMilliseconds} from '../../lib/TimeToMilliseconds';
import {DisplayFrequencyConfig, DisplayLimitConfig} from '../WebTriggerData';

export const IMPRESSION_DATA_KEY = 'zaius_web_content';
const DATA_MAX_AGE = 365;

interface Impressions {
  [contentId: string]: number[];
}

let loadedImpressions: Impressions = {};
let loaded = false;

export function meetsFrequencyConstraints(contentId: string, frequency?: DisplayFrequencyConfig, limit?: DisplayLimitConfig) {
  const impressions = readImpressions()[contentId];
  if (!impressions || impressions.length === 0) {
    return true;
  }

  if (limit && limit.enabled && impressions.length >= limit.limit) {
    return false;
  }

  if (frequency && frequency.enabled) {
    const timeOfConcern = (new Date().getTime() - timeToMilliseconds(frequency.time, frequency.timeUnit)) / 1000;
    let count = 0;
    for (let i = impressions.length - 1; i >= 0; i--) {
      if (impressions[i] < timeOfConcern) {
        break;
      }
      count++;
    }
    if (count >= frequency.count) {
      return false;
    }
  }

  return true;
}

export function recordImpression(contentId: string) {
  const impressions = readImpressions();
  if (!Array.isArray(impressions[contentId])) {
    impressions[contentId] = [];
  }
  impressions[contentId].push(Math.round(new Date().getTime() / 1000));

  const data = JSON.stringify(impressions);
  if (typeof window.localStorage === 'undefined') {
    // localStorage doesn't work across subdomains, so don't use the configured cookie domain for parity
    utils.setCookie(IMPRESSION_DATA_KEY, data, DATA_MAX_AGE);
  } else {
    window.localStorage.setItem(IMPRESSION_DATA_KEY, data);
  }
}

function readImpressions() {
  if (loaded) {
    return loadedImpressions;
  }
  loaded = true;

  let data: string | undefined | null;
  if (typeof window.localStorage === 'undefined') {
    data = utils.getCookie(IMPRESSION_DATA_KEY);
  } else {
    data = window.localStorage.getItem(IMPRESSION_DATA_KEY);
  }

  if (!data) {
    return loadedImpressions = {};
  }

  try {
    loadedImpressions = JSON.parse(data);
    if (!utils.isObject(loadedImpressions)) {
      loadedImpressions = {};
    }

    const pruneDate = new Date().getTime() / 1000 - DATA_MAX_AGE * 86400;
    for (const id in loadedImpressions) {
      if (loadedImpressions.hasOwnProperty(id)) {
        const list = loadedImpressions[id].sort();
        let i = 0;
        for (; i < list.length; i++) {
          if (list[i] > pruneDate) {
            break;
          }
        }
        list.splice(0, i);
      }
    }

    return loadedImpressions;
  } catch (e) {
    return loadedImpressions = {};
  }
}

if (SDK_TEST_MODE) {
  __internals.FrequencyConstraints = {
    reset() {
      loadedImpressions = {};
      loaded = false;
    },
    readImpressions: () => readImpressions()
  };
}
