import tracker from './tracker';
import domready from './vendor/domready';
import {utils} from './utils';

// Public API
// ----------
// methods: initialize, onload, customer, entity, event, subscribe, unsubscribe, consent, identify, anonymize, dispatch, _tracker, utils, webPush

// Expose the tracker in the global namespace for client interaction.
// Using the associative array syntax here to avoid minification
// mangling.
//
const revealZaiusJSAPI = function () {
  window['zaius']['initialize'] = function (trackerId, opts) {
    tracker.initialize(trackerId, opts);
    return this;
  };

  window['zaius']['onload'] = function (handler) {
    if (handler instanceof Function) {
      // Zaius JS is loaded, just call handler
      setTimeout(handler, 0);
    }
  };

  window['zaius']['customer'] = function (identifiers, attributes) {
    return tracker.customer(identifiers, attributes);
  };

  window['zaius']['entity'] = function (entityType, entity) {
    return tracker.entity(entityType, entity);
  };

  window['zaius']['event'] = function (eventType, event) {
    return tracker.event(eventType, event);
  };

  window['zaius']['subscribe'] = function (subscriptionObject, onSuccess, onError) {
    tracker.subscribe(subscriptionObject, onSuccess, onError);
    return this;
  };

  window['zaius']['unsubscribe'] = function (subscriptionObject, onSuccess, onError) {
    tracker.unsubscribe(subscriptionObject, onSuccess, onError);
    return this;
  };

  window['zaius']['consent'] = function (consentObject, onSuccess, onError) {
    return tracker.consent(consentObject, onSuccess, onError);
  };

  window['zaius']['identify'] = function (customerId) {
    tracker.identify(customerId);
    return this;
  };

  window['zaius']['anonymize'] = function () {
    tracker.anonymize();
    return this;
  };

  window['zaius']['dispatch'] = function () {
    tracker.dispatch.apply(tracker, arguments);
    return this;
  };

  // required for testing our production JS
  window['zaius']['_tracker'] = tracker;

  window['zaius']['utils'] = {
    'getCookie': utils.getCookie,
    'setCookie': utils.setCookie
  };

  window['zaius']['webPush'] = tracker.webPush;
};

// Zaius JS OnLoad Handlers
// ---------------------

// Applications using Zaius JS may want to be notified when it has
// completed initialization. They may register a handler by calling
// zaius.onload and it will be called when Zaius JS initialization is
// complete. If Zaius JS has been initialized, the function will be
// called immediately.
const processOnloadHandlers = function (onLoadHandlers) {
  for (let i = 0; i < onLoadHandlers.length; i++) {
    if (onLoadHandlers[i][0] instanceof Function) {
      onLoadHandlers[i][0]();
    }
  }
};

// Create the global zaius object if it has not already been defined
window['zaius'] || (window['zaius'] = {});

if (!window['zaius']['loaded']) {
  // OnLoad Handler
  // --------------

  // Wait for the dom ready event before starting Zaius JS.
  // NOTE: page load and total load time is not collected with this method
  // Any queued pageview requests will be made in a subsequent event loop
  // cycle to the event loop cycle that is processing the ready event.
  //
  domready(function () {
    setTimeout(function () {
      const onloadHandlers = tracker.processQueued();
      // need to wait to reveal non-queue Zaius JS API till
      // after async queue has been processed
      revealZaiusJSAPI();
      // now that zaius has been initialized, notify any listeners
      processOnloadHandlers(onloadHandlers);
    }, 0);
  });

  // set-up Zaius JS methods queue to handle any calls to Zaius JS prior to initialization
  if (!(window['zaius']['initialize'] instanceof Function)) {
    zaius = window['zaius'] = [];
    zaius.methods = ['initialize', 'onload', 'customer', 'entity', 'event', 'subscribe', 'unsubscribe', 'consent', 'identify', 'anonymize', 'dispatch'];
    zaius.factory = function (e) {
      return function () {
        const t = Array.prototype.slice.call(arguments);
        t.unshift(e);
        zaius.push(t);
        return zaius;
      };
    };
    for (let i = 0; i < zaius.methods.length; i++) {
      const method = zaius.methods[i];
      zaius[method] = zaius.factory(method);
    }
  }

  window['zaius']['loaded'] = true;
}
