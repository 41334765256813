import {EventAction} from '../Zaius';
import {ContentManagerBase} from './ContentManagerBase';
import {CustomerProfileData} from './CustomerProfileData';
import {ListSubscriptionData} from './ListSubscriptionData';
import {WebContentConfig} from './ZedWebData';
import {ProductRecommendation} from './product-recommendations/ProductRecomendation';
import {StaticContext} from './static-context/StaticContext';

export interface GridData {
  grid_id: number;
  data: ProductRecommendation[];
}

interface InitializationPayload {
  user: {
    email?: string;
  };
  config: WebContentConfig;
  render_context: RenderContext;
}

export interface RenderContext {
  variables: Record<string, unknown>;
}

export interface RenderContextData {
  gridData: GridData[];
  staticContext: StaticContext;
}

export interface MessagePayload<T = any> {
  action: string;
  payload: T;
  callback?: number;
}

interface ChannelMessageEvent<T = any> extends MessageEvent {
  data: MessagePayload<T>;
}

export class WebContentInterface {
  private channel: MessageChannel;

  constructor(private contentManager: ContentManagerBase, private contentWindow: Window) {
    this.channel = new MessageChannel();
    this.channel.port1.onmessage = this.handleMessage;
  }

  public initialize(payload: InitializationPayload) {
    const message = {
      action: 'initialize',
      payload
    };
    this.contentWindow.postMessage(message, '*', [this.channel.port2]);
  }

  public sendListSubscriptionData(action: string, payload: ListSubscriptionData) {
    this.contentWindow.postMessage({action, payload}, '*');
  }

  public sendCustomerProfileData(action: string, payload: CustomerProfileData) {
    this.contentWindow.postMessage({action, payload}, '*');
  }

  private handleMessage = (e: ChannelMessageEvent) => {
    const {contentManager} = this;
    const {payload, action, callback} = e.data;
    const triggerCallback = this.createCallbackTrigger(callback);

    try {
      switch (action) {
        case 'size_changed':
          contentManager.onSizeChange(payload);
          break;

        case 'show_confirmation':
          contentManager.showConfirmation();
          break;

        case 'link_click':
          contentManager.beforeRedirect();
          const thenRedirect = () => window.location.assign(payload);
          contentManager.fireWebEvent(contentManager.webMode, {
            action: EventAction.Click,
            value: payload
          })
            .then(thenRedirect)
            .catch(thenRedirect);
          break;

        case 'redirect':
          contentManager.beforeRedirect();
          window.location.assign(payload);
          break;

        case 'close_modal':
          this.contentManager.destroy();
          break;

        case 'entity':
          try {
            contentManager.fireEntity(payload.type, payload.data)
              .then(() => triggerCallback(true), () => triggerCallback(false));
          } catch (e) {
            triggerCallback(false);
          }
          break;

        case 'event':
          try {
            contentManager.fireWebEvent(payload.type, payload.data)
              .then(() => triggerCallback(true), () => triggerCallback(false));
          } catch (e) {
            triggerCallback(false);
          }
          break;

        case 'after_submit':
          contentManager.afterSubmit();
          break;

        case 'after_opt_out':
          contentManager.afterOptOut();
          break;

        case 'content_ready':
          contentManager.contentReady();
          triggerCallback(true);
          break;
        case 'consent_change':
          contentManager.fireConsentChange(payload)
            .then(() => triggerCallback(true), () => triggerCallback(false));
          break;
      }
    } catch (exception) {
      contentManager.reportError(`Error handling message from web content: ${exception}\n${exception.stack}`);
    }
  };

  private createCallbackTrigger(callbackId?: number) {
    if (callbackId != null) {
      return (success: boolean) => {
        this.contentWindow.postMessage({
          action: 'callback',
          callback: callbackId,
          payload: {success}
        }, '*');
      };
    }

    return () => {return; };
  }
}
