export enum MobileTypes {
  iOS = 'iOS',
  Android = 'Android',
  Windows = 'Windows',
  Unknown = 'unknown'
}

export namespace MobileUtilities {
  export function isMobileIOS(): boolean {
    return getMobileOperatingSystem() === MobileTypes.iOS;
  }

  export function isMobileAndroid(): boolean {
    return getMobileOperatingSystem() === MobileTypes.Android;
  }

  export function isMobileWindows(): boolean {
    return getMobileOperatingSystem() === MobileTypes.Windows;
  }

  export function isMobile(): boolean {
    return getMobileOperatingSystem() !== MobileTypes.Unknown;
  }

  /********
   * https://stackoverflow.com/questions/21741841
   * Get browser type from the userAgent
   *
   * @return String
   ********/
  export function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;
    let operatingSystem = MobileTypes.Unknown;

    if (/windows phone/i.test(userAgent)) {
      operatingSystem  = MobileTypes.Windows;
    } else if (/android/i.test(userAgent)) {
      operatingSystem = MobileTypes.Android;
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      operatingSystem = MobileTypes.iOS;
    }

    return operatingSystem;
  }
}
