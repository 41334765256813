/***
 * Given the bounds of a DOMRect object, return the midpoint / center coordinates
 *
 * @param bounds - DOMRect object
 * @returns Object with x and y coordinates
 ***/
function getMidpointFromBounds(bounds: DOMRect | ClientRect) {
  return { x: bounds.left + (bounds.width / 2), y: bounds.top + (bounds.height / 2) };
}

/***
 * Given an iFrame object, return if the center of the iFrame is in the window / document view
 *
 * @param currFrame - iFrame object
 * @returns {boolean}
 ***/
export function isBoundsInViewPort(currFrame: HTMLElement) {
  const center = getMidpointFromBounds(currFrame.getBoundingClientRect());
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

  return (center.y <= windowHeight && center.y >= 0) && (center.x <= windowWidth && center.x >= 0);
}
