import {
  HashSchema, REQUIRED_OBJECT} from '../lib/ValidateData';

export interface CustomerProfileResponse {
  attributes: CustomerProfileData;
}

export interface CustomerProfileData {
  [field: string]: string | number | boolean | null;
}

export const CUSTOMER_PROFILE_REQUEST_SCHEMA: HashSchema<CustomerProfileResponse> = {
  type: REQUIRED_OBJECT,
  properties: {
    attributes: REQUIRED_OBJECT
  }
};
