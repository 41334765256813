import {utils} from './utils';
import tracker from './tracker';
import {search} from './search';

export const dimensions = {

  // Title
  // -----

  // Get the title param to send in the beacon.
  //
  title() {
    return document.title;
  },

  hasCustomerId(params) {
    return 'customer_id' in params;
  },

  // Page
  // ----

  // Get the page param to send in the beacon.
  //
  page() {
    let path = utils.windowPathname();

    if (tracker.config['includeQueryString'] === true) path += utils.windowSearch();
    if (tracker.config['includeHash'] === true) path += utils.windowHash();
    return path;
  },

  // Viewport
  // --------

  // Viewport hackery.  Favor the window.innerWidth or innerHeight.  When not
  // available use the document.documentElement or the document.body.
  //
  viewport() {
    let el, viewport, width, height;
    const pixelRatio = window.devicePixelRatio ? window.devicePixelRatio : 1;

    if (window.innerWidth) {
      width = (window.innerWidth * pixelRatio).toFixed();
      height = (window.innerHeight * pixelRatio).toFixed();

      viewport = width + 'x' + height;

    } else if (el = document.documentElement || document.body) {
      width = (el.clientWidth * pixelRatio).toFixed();
      height = (el.clientHeight * pixelRatio).toFixed();

      viewport = width + 'x' + height;
    }

    return viewport;
  },

  // Screen Info
  // -----------

  // Get the screen resolution & screen pixel params to send in the beacon.
  //
  // * `page` the optional page value supplied by the user, defaults to `location.pathname + location.search`
  //
  // TODO: screen.colorDepth is not available in ie 7 on vista, pixel depth is.
  //
  screenInfo() {
    const viewport = this.viewport();
    const pixelRatio = window.devicePixelRatio ? window.devicePixelRatio : 1;
    const width = (screen.width * pixelRatio).toFixed();
    const height = (screen.height * pixelRatio).toFixed();
    const info = {
      'resolution': width + 'x' + height,
      'color_depth': screen.colorDepth + '-bit'
    };

    if (viewport) info['viewport'] = viewport;
    return info;
  },

  // Is Java Enabled
  // ---------------

  // Determine if Java is enabled.  Check the 'application/x-java-vm' mime
  // registries and then try navigator.javaEnabled(). Solution derived from
  // Piwik <http://piwik.org/>.
  //
  isJavaEnabled() {
    const mimes = navigator.mimeTypes;
    const mime = mimes ? mimes['application/x-java-vm'] : undefined;
    let enabled = mime && mime.enabledPlugin;

    if (!enabled) {
      enabled =
        typeof navigator.javaEnabled !== 'unknown'
        && utils.isDefined(navigator.javaEnabled)
        && navigator.javaEnabled();
    }

    return enabled;
  },

  // Flash Version
  // -------------

  // Determine the installed flash version installed and enabled.
  //
  // Heavily inspired by GA & SWFObject v2.2 <http://code.google.com/p/swfobject/>
  //
  flashVersion() {
    const plugins = navigator.plugins;
    const plugin = plugins && utils.isObject(plugins['Shockwave Flash']) ? plugins['Shockwave Flash'] : undefined;
    const mimes = navigator.mimeTypes;
    const flash = mimes ? mimes['application/x-shockwave-flash'] : undefined;
    const enabled = flash && flash.enabledPlugin;
    let playerVersion;

    if (plugin) {
      const desc = plugin.description;
      // navigator.mimeTypes["application/x-shockwave-flash"].enabledPlugin
      // indicates whether plug-ins are enabled or disabled in Safari 3+
      if (desc && enabled) {
        playerVersion = desc.replace(/^.*\s+(\S+\s+\S+$)/, '$1');
      }

    } else if (window.ActiveXObject) {
      let activeX, shockwave = 'ShockwaveFlash.ShockwaveFlash';
      try {
        activeX = new ActiveXObject(shockwave + '.7');
        playerVersion = activeX.GetVariable('$version');
      } catch (e) {}

      if (!playerVersion) {
        try {
          activeX = new ActiveXObject(shockwave + '.6');
          playerVersion = 'WIN 6,0,21,0';
          activeX.AllowScriptAccess = 'always';
          playerVersion = activeX.GetVariable('$version');
        } catch (e) {}
      }

      if (!playerVersion) {
        try {
          activeX = new ActiveXObject(shockwave);
          playerVersion = activeX.GetVariable('$version');
        } catch (e) {}
      }

      if (playerVersion) {
        playerVersion = playerVersion.split(' ')[1].split(',');
        playerVersion = playerVersion[0] + '.' + playerVersion[1] + ' r' + playerVersion[2];
      }
    }

    return playerVersion;
  },

  // Browser Features
  // ----------------

  // Collect the browser features
  //
  browserFeatures() {
    const flashVersion = this.flashVersion();
    const language = navigator.language || navigator.browserLanguage;
    const characterSet = document.characterSet || document.charset;
    const features = {'java': this.isJavaEnabled() ? 1 : 0};

    if (flashVersion) features['flash'] = flashVersion;
    if (language) features['language'] = language.toLowerCase();
    if (characterSet) features['character_set'] = characterSet.toLowerCase();

    return features;
  },

  // Traffic Sources
  // ---------------

  // Collect the referrer info: source, medium, keywords, etc
  //
  trafficSources() {
    let traffic = {};
    let referral = {};
    const campaign = this.campaign();

    if (campaign) {
      traffic = campaign;

    } else if (document.referrer) {
      const referrer = utils.parseUrl(document.referrer)
      const sameDomain = (referrer.hostname === document.location.hostname);

      if (!sameDomain) {
        referral['referrer'] = referrer.full;
        referral['referral_host'] = referrer.hostname;
        referral['referral_path'] = referrer.path;
        const searchMeta = search.meta(referrer);
        if (searchMeta) {
          if (searchMeta['original_referral']) {
            referral = searchMeta['original_referral'];
            delete searchMeta['original_referral'];
          }
          utils.mixin(traffic, searchMeta);
        } else {
          traffic['source'] = referrer.hostname;
          traffic['medium'] = 'referral';
        }
      }
    }

    return utils.mixin(referral, this.currentReferrer(traffic));
  },

  // Current Referrer
  // ----------------

  // Get and re(set) the `vtsrc` cookie.  This cookie value contains the last
  // known referrer information.  It is updated with the current referrer info
  // in the following cases:
  // 1.  not set/first visit
  // 2.  non-search referrals, ie original (current cookie value)
  //     and current referrer are not campaign or search
  // 3.  every campaign or search referral
  //
  //
  // if the original referrer is updated on the initial call an landing
  // param is included in the resulting object.
  //
  currentReferrer(traffic) {
    const cookie = utils.getCookie('vtsrc');
    let original = cookie ? utils.decodeObject(cookie) : {'source': 'direct', 'medium': 'none'};
    const search = (traffic['medium'] === 'organic' || traffic['medium'] === 'cpc');
    const referral = traffic['medium'] === 'referral';
    const direct = traffic['source'] === 'direct';
    const campaign = !!traffic['isCampaign'];
    const expires = tracker.config['referrerCookieExpiryDays'];
    const domain = tracker.config['cookieDomain'];
    let landing;

    if (campaign || search) {
      original = traffic;
      landing = true;

    } else if (referral) {
      if (original['medium'] === 'none' || original['medium'] === 'referral') {
        original = traffic;
        landing = true;
      }
    }

    const encoded = utils.encodeObject(original);
    utils.setCookie('vtsrc', encoded, expires, domain);

    const result = utils.deepClone(original);
    if (!this.referrerRecorded) {
      this.referrerRecorded = true;
      if (landing) result['landing'] = 1;
    }
    return result;
  },

  // Campaign
  // --------

  // Collect all the campaign tracking related dimensions.  Currently we
  // will support, in addition to the vdl defined fields, the Google Analytics
  // `utm_` based fields.  Campaign params will be detected in either the
  // query string or in the hash tag.  The advantage of using the hash
  // tag over the query string is that campaign tracking URLs will not be
  // considered duplicate URLs by search engines.
  //
  campaign() {
    let s;
    let source = (s = utils.param('campaignSource') || utils.param('utm_source')) ? s.replace(/\|/g, '_') : null;
    let campaign = (s = utils.param('campaignName') || utils.param('utm_campaign')) ? s.replace(/\|/g, '_') : null;
    let medium = (s = utils.param('campaignMedium') || utils.param('utm_medium')) ? s.replace(/\|/g, '_') : null;
    const gclid = (s = utils.param('gclid')) ? s : null;

    if (gclid && (!source && !campaign && !medium)) {
      campaign = 'auto-tagged';
      medium = 'cpc';
      source = 'google';
    }

    if (source) {
      return utils.compact({
        'isCampaign': true,
        'source': source,
        'campaign': campaign,
        'medium': medium,
        'keywords': (s = utils.param('campaignKeyword') || utils.param('utm_term')) ? s.replace(/\|/g, '_') : null,
        'content': (s = utils.param('campaignContent') || utils.param('utm_content')) ? s.replace(/\|/g, '_') : null
      });
    }
  },

  // Performance Metrics
  // -------------------

  // Collect the HTML5 performance metrics if they are available
  //
  performanceMetrics() {
    let t, metric, metrics = {};
    if (!this.performanceCaptured && window.performance) {
      this.performanceCaptured = true;
      if (t = window.performance.timing) {
        const calc = function (a, b) {return a && b && a > b && (a - b) < 86400000 ? a - b : false;};
        if (metric = calc(t.redirectEnd, t.redirectStart))
          metrics['redirect_time'] = metric;

        if (metric = calc(t.domainLookupEnd, t.domainLookupStart))
          metrics['domain_lookup_time'] = metric;

        if (metric = calc(t.connectEnd, t.connectStart))
          metrics['server_connect_time'] = metric;

        if (metric = calc(t.responseStart, t.requestStart))
          metrics['server_response_time'] = metric;

        if (metric = calc(t.responseEnd, t.responseStart))
          metrics['page_download_time'] = metric;

        if (metric = calc(t.loadEventEnd, t.domLoading))
          metrics['page_load_time'] = metric;

        if (metric = calc(t.loadEventEnd, t.navigationStart))
          metrics['total_load_time'] = metric;
      }
    }

    return metrics;
  },

  // Days Since Last Visit
  // ---------------------

  // Determine the days since last visit.  Values are withing the range of
  // 0-60 days, where 0 means "within 24 hours" and 1 means "between 1 -2 days".
  //
  TWENTY_FOUR_HOURS: 24 * 60 * 60 * 1000,
  daysSinceLastVisit() {
    const days = {};

    if (this.lastVisit) {
      const now = new Date().getTime();
      const lastVisit = new Date(+this.lastVisit);
      const delta = now - lastVisit;

      days['days_since_last_visit'] = Math.floor(delta / this.TWENTY_FOUR_HOURS);
    }

    return days;
  },

  // Decode Params
  // -------------------

  // Convert the query string and hash parameters to event fields.
  //
  decodeParams() {
    return utils.params(tracker.config['convertParameters'] === true)
  },

  // Collect
  // -------

  // Collects all dimensions
  //
  collect() {
    const decodedParams = this.decodeParams();
    const screenInfo = this.screenInfo();
    const trafficSources = this.trafficSources();
    const features = this.browserFeatures();
    const lastVisit = this.daysSinceLastVisit();
    const performance = this.performanceMetrics();
    const hostname = window.location.hostname;
    const cacheBuster = utils.random();
    const overrides = {
      'hostname': hostname,
      'page': this.page(),
      'title': this.title(),
      'u': cacheBuster
    };
    return utils.mixin(decodedParams, screenInfo, trafficSources, features, lastVisit, performance, overrides);
  }

};
