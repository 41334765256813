import {ProductRecommendationSources, ProductRecommendationSourceType} from '../lib/RecentlyViewedProducts';
import {FieldRequirement, HashSchema, REQUIRED_NUMBER, REQUIRED_OBJECT, REQUIRED_STRING} from '../lib/ValidateData';

export enum WebMode {
  Email = 'email',
  WebEmbed = 'web_embed',
  PreferenceCenter = 'preference_center',
  LandingPage = 'landing_page',
  WebModal = 'web_modal'
}

export enum ContentSection {
  Main = 'main',
  Confirmation = 'confirmation'
}

export enum Vendor {
  Zaius = 'zaius'
}

export interface ModalProperties {
  width: number;
  backdropColor: string;
  hasCloseButton: boolean;
  closeButtonInside: boolean;
}

// Data used by embedded JS in web content
export interface ContentDetails {
  id: number;
  type: WebMode;
  eventEnrichment: {
    campaign_group_id?: number;
    campaign_id?: number;
    campaign?: string;
    touchpoint_id?: number;
    content_id?: number;
    content?: string;
    identifier_key?: string;
  };
  // Only provided for landing page types
  tracker_id?: string;
}

export interface GridConfig {
  id: number;
  type: ProductRecommendationSourceType;
  source: ProductRecommendationSources;
  filter?: string;
  max_rows: number;
  max_columns: number;
  min_rows: number;
  min_columns: number;
}

export interface WebContentConfig {
  content: ContentDetails;
  properties: {
    minHeight: number;
    width: number;
  };
  modal?: ModalProperties;
  components: ComponentDetails[];
  grids?: GridConfig[];
}

export interface ComponentDetails {
  id: number;
  type: string;
  constraints: {
    required: boolean;
  };
  action: {
    [key: string]: any; // TBD (in flux right now)
  };
}

export interface ZedWebData {
  main: string;
  confirmation: string;
  config: WebContentConfig;
  script?: string;
}

export const ZED_WEB_DATA_SCHEMA: HashSchema<ZedWebData> = {
  type: REQUIRED_OBJECT,
  properties: {
    main: REQUIRED_STRING,
    confirmation: FieldRequirement.String,
    script: FieldRequirement.String,
    config: {
      type: REQUIRED_OBJECT,
      properties: {
        content: {
          type: REQUIRED_OBJECT,
          properties: {
            id: REQUIRED_NUMBER,
            type: {
              type: REQUIRED_STRING,
              oneOf: Object.values(WebMode)
            },
            eventEnrichment: REQUIRED_OBJECT,
            tracker_id: FieldRequirement.String
          }
        },
        properties: {
          type: FieldRequirement.Object,
          properties: {
            minHeight: FieldRequirement.StringOrNumber,
            width: FieldRequirement.StringOrNumber
          }
        },
        modal: {
          type: FieldRequirement.Object,
          properties: {
            width: REQUIRED_NUMBER,
            backdropColor: REQUIRED_STRING,
            hasCloseButton: FieldRequirement.Boolean
          }
        },
        components: {
          type: FieldRequirement.Array,
          all: {
            type: REQUIRED_OBJECT,
            properties: {
              id: REQUIRED_NUMBER,
              type: REQUIRED_STRING,
              constraints: {
                type: REQUIRED_OBJECT,
                properties: {
                  required: FieldRequirement.Boolean
                }
              },
              action: FieldRequirement.Object // TBD
            }
          }
        }
      }
    }
  }
};
