import {
  HashSchema, REQUIRED_ARRAY, REQUIRED_OBJECT, REQUIRED_STRING
} from '../../lib/ValidateData';

export interface Product {
  product_id: string;
  name: string;
  price?: string | null;
  image_url?: string | null;

  [field: string]: string | number | boolean | null | undefined;
}

export const enum ProductRecommendationType {
  PERSONAL = 'personal',
  CONTEXTUAL = 'contextual',
  BEST_SELLERS = 'best-sellers'
}

export type ProductRecommendation =
  Product
  & { recommendation_type: ProductRecommendationType };

export const PRODUCT_RECOMMENDATION_SCHEMA: HashSchema<ProductRecommendation[]> = {
  type: REQUIRED_ARRAY,
  all: {
    type: REQUIRED_OBJECT,
    properties: {
      product_id: REQUIRED_STRING,
      recommendation_type: {
        type: REQUIRED_STRING,
        oneOf: ['personal', 'contextual', 'best_sellers']
      }
    }
  }
};
