export const utils = {

  /**
   * Generates a query string.
   *
   * @param {Object} obj the object to serialize into a query string
   * @param {string=} prefix optional prefix
   * @param {string[]=} encodingIgnored  this properties will not be encoded
   * @returns {string}
   */
  queryStringify(obj, prefix, encodingIgnored) {
    const params = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? prefix + '[' + p + ']' : p;
        const v = obj[p];
        if (typeof v !== 'undefined' && v !== null) {
          params.push(
            typeof v === 'object'
              ? this.queryStringify(v, k)
              : encodeURIComponent(k) + '=' + (encodingIgnored ? encodingIgnored.includes(k) ? v : encodeURIComponent(v) : encodeURIComponent(v))
          );
        }
      }
    }

    return params.join('&');
  },

  // Param
  // ----

  // Find the value of the a param in either the query string or the hash.
  // Returns `null` if not found.
  //
  // * `name` - the param name (key) to search for.
  //
  param(name) {
    const regex = '[\\?&]' + name + '=([^&#]*)';
    const result = new RegExp(regex).exec(this.windowHref());

    return result ? decodeURIComponent(result[1]) || null : null;
  },

  // windowHref
  // ----
  //
  // Returns the window.location.href. Useful to override when testing.
  windowHref() {
    return window.location.href;
  },

  // windowPathname
  // ----
  //
  // Returns the window.location.pathname. Useful to override when testing.
  windowPathname() {
    return window.location.pathname;
  },

  // windowSearch
  // ----
  //
  // Returns the window.location.search. Useful to override when testing.
  windowSearch() {
    return window.location.search;
  },

  // windowHash
  // ----
  //
  // Returns the window.location.hash. Useful to override when testing.
  windowHash() {
    return window.location.hash;
  },

  // Params
  // ----

  // Returns an object representing the params found in both the query string and hash.
  //
  params(convertAllParams) {
    const qsParams = this.windowSearch().substr(1).split('&');
    const hashParams = this.windowHash().substr(1).split('&');
    let parts = [];
    const params = {};

    if (qsParams != '') parts = parts.concat(qsParams);
    if (hashParams != '') parts = parts.concat(hashParams);

    if (parts.length) {
      for (let i = 0; i < parts.length; i++) {
        const param = parts[i].split('=');
        if (param.length === 2) {
          const prop = decodeURIComponent(param[0]).replace(/\s+/g, '_');
          // always return identifier fields from query params
          if (convertAllParams || prop === 'zm64_id' || prop.match(/^zaius_alias_.+/)) {
            params[prop] = decodeURIComponent(param[1]);
          }
        }
      }
    }

    return params;
  },

  // Get cookie
  // ----------

  // Fetch the cookie value by name.
  //
  // * `name` the name of the cookie to pull the value from
  //
  getCookie(name) {
    let x, y;
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      x = cookies[i].substr(0, cookies[i].indexOf('='));
      y = cookies[i].substr(cookies[i].indexOf('=') + 1);
      x = x.replace(/^\s+|\s+$/g, '');
      if (x == name) {
        try {
          return decodeURIComponent(y);
        } catch (e) {
          return unescape(y);
        }
      }
    }
  },

  /**
   * Sets or resets a first class cookie.
   *
   * @param {string} name the cookie name
   * @param {string} value the cookie value
   * @param {number} expDays the expiration in days
   * @param {string=} domain the domain in which the cookie is available
   */
  setCookie(name, value, expDays, domain) {
    value = encodeURIComponent(value) + '; ';

    let expires = new Date();
    expires.setDate(expires.getDate() + expDays);
    expires = 'expires=' + expires.toUTCString() + '; ';
    domain = domain ? 'domain=' + domain + ';' : '';

    document.cookie = name + '=' + value + expires + 'path=/; ' + domain;
  },

  // Generate UUID
  // -------------

  // Generate a UUID.
  //
  generateUUID() {
    let uuid = '', i, random;
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += '-';
      }
      uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
  },

  // Random
  // ------

  // Generate a random #
  //
  random() {
    return Math.round(2147483647 * Math.random());
  },

  /**
   * Mixes the properties from the given sources onto the obj.
   *
   * @param {Object} obj the object to mix the properties into
   * @param {...Object} sources variable number of source objects, applied in order
   * @returns {Object} the destination object
   */
  mixin(obj, ...sources) {
    for (let i = 0, source; source = sources[i]; i++) {
      for (const property in source) {
        if (Array.isArray(source[property])) {
          obj[property] = [];
          for (let j = 0; j < source[property].length; j++) {
            obj[property][j] = source[property][j];
          }
        } else if(this.isObject(obj[property]) && source[property] && this.isObject(source[property])) {
          if (!obj[property]) {
            obj[property] = source[property];
          } else {
            this.mixin(obj[property], source[property]);
          }
        } else {
          obj[property] = source[property];
        }
      }
    }
    return obj;
  },

  // Override
  // --------

  // Override the specific keys, if exist, from the source onto the dest.
  //
  override(src, keys, dest) {
    for (let i = 0; i < keys.length; i++) {
      if (src[keys[i]] !== undefined) {
        dest[keys[i]] = src[keys[i]];
      }
    }

    return dest;
  },

  // Clone
  // -----

  /**
   * Create a shallow copy of the passed object.
   *
   * @param {Object} obj the object to clone
   * @param {string=} text optional text to prepend to the property names
   * @returns {Object}
   */
  clone(obj, text) {
    if (!this.isObject(obj)) return obj;
    const clone = {};
    text = text || '';
    for (const property in obj) {
      clone[text + property] = obj[property];
    }
    return clone;
  },

  // Deep Clone
  // ----------

  // Create a deep copy of the passed object. Supports arrays and regular hashes.
  // Only includes properties where hasOwnProperty() is true
  //
  // * `obj` the object to clone
  //
  deepClone(obj) {
    if (!this.isObject(obj)) return obj;

    const clone = Array.isArray(obj) ? [] : {};
    for (const property in obj) {
      if (obj.hasOwnProperty(property))
        clone[property] = this.deepClone(obj[property]);
    }
    return clone;
  },

  // Compact
  // -------

  // Removes null or undefined or NaN (the symbol) properties from the passed object.
  //
  // * `obj` the object to compact
  //
  compact(obj) {
    if (!this.isObject(obj)) return obj;
    for (const property in obj) {
      // check for value === NaN using value !== value
      // Effective Javascript: Since NaN is the only JavaScript value that is
      // treated as unequal to itself, you can always test if a value is NaN
      // by checking it for equality to itself:
      const value = obj[property];
      const prune = (value === null || value === undefined || value !== value || value === 'undefined');

      if (prune) delete obj[property];
    }

    return obj;
  },

  // Number of Own Properties
  // ------------------------

  // Get the number of properties of the given object.
  //
  // * `obj` the object whose properties we're counting
  //
  numProperties(obj) {
    if (!this.isObject(obj)) return 0;

    let numProperties = 0;
    for (const property in obj) {
      if (obj.hasOwnProperty(property))
        numProperties++;
    }
    return numProperties;
  },

  // Encode Object
  // -------------

  // Encode an object to a `|` separated string of key value pairs.  Used
  // to encode values into cookies.
  //
  encodeObject(obj) {
    if (!this.isObject(obj)) return obj;
    const keyValues = [];
    for (const property in obj) {
      keyValues.push(property + '=' + obj[property]);
    }

    return keyValues.join('|');
  },


  // Decode Object
  // -------------

  // Decode an object from a `|` separated string of key value pairs.  Used
  // to decode values into cookies.
  //
  decodeObject(str) {
    if (!this.isString(str)) return str;
    const decoded = {}, keyValues = str.split(/\|/g);
    for (let i = 0; i < keyValues.length; i++) {
      const keyValue = keyValues[i].split(/=/);
      decoded[keyValue[0]] = keyValue[1];
    }

    return decoded;
  },

  // Is String
  // ---------

  // Determine if the obj is an instance of String.
  //
  // * `obj` the object to test
  //
  isString(obj) {
    return Object.prototype.toString.call(obj) == '[object String]';
  },

  // Trim whitespace beginning or ending string.
  //
  // * `str` the string to trim
  //
  trimString(str) {
    if (!this.isString(str)) return str;
    return str.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
  },

  // Is ASCII
  // --------

  // Determine if the obj is a string containing only ASCII characters
  //
  // * `obj` the object to test
  //
  isAscii(obj) {
    return this.isString(obj) && /^[\x00-\x7F]*$/.test(obj);
  },

  // Is Boolean
  // -----------

  // Determine if the obj is a boolean.
  //
  // * `obj` the object to test
  //
  isBoolean(obj) {
    return typeof obj === 'boolean';
  },

  // Is Number
  // -----------

  // Determine if the obj is an integer.
  //
  // * `obj` the object to test
  //
  isInteger: Number.isInteger || function(obj) {
    return typeof obj === 'number'
      && isFinite(obj)
      && Math.floor(obj) === obj;
  },

  // Is Object
  // ---------

  // Determine if the obj is an instance of Object.
  //
  // * `obj` the object to test
  //
  isObject(obj) {
    return obj === Object(obj);
  },

  // Is Function
  // -----------

  // Determine if the obj is a function.
  //
  // * `obj` the object to test
  //
  isFunction(obj) {
    return typeof obj === 'function'
  },

  // Is Defined
  // ----------

  // Determine if the object is not undefined.  Not `null` is defined.
  //
  // * `obj` the object to test
  //
  isDefined(obj) {
    return obj !== void 0;
  },

  // Is Valid Email
  // --------

  // Determine if the object is a valid email based on [RFC for emails](https://tools.ietf.org/html/rfc2822#section-3.4.1).
  //
  // * `email` the email to test for validity
  //
  EMAIL_PATTERN: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  isValidEmail(email) {
    return this.isString(email) && email.match(this.EMAIL_PATTERN) && email.length < 255;
  },

  // For In
  // ------
  //
  // A (mostly) mutable-safe for(key in object) replacement
  //
  // * `obj` object whose keys you want to iterate
  // * `callback` a callback function defined as function(key, value) called for each key of obj
  //
  forIn(obj, callback) {
    const keys = [];
    const values = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        keys.push(key);
        values.push(obj[key]);
      }
    }
    for (let i = 0; i < keys.length; i++) {
      callback(keys[i], values[i]);
    }
  },

  // Parse URL
  // ---------

  // Parse a URL based on [RFC for URIs](http://www.ietf.org/rfc/rfc3986.txt).
  //
  // * `url` the url string.
  //
  URL_PATTERN: /^(([^:\/?#]+):)?(\/\/([^\/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/,
  parseUrl(url) {
    if (this.isString(url)) {
      const parts = url.match(this.URL_PATTERN);
      return {
        full: url,
        protocol: parts[2],
        hostname: parts[4],
        path: parts[5],
        queryString: parts[7] ? decodeURIComponent(parts[7]) : ''
      };
    }
  },

  // Semantic Version Less Than
  // --------------------------

  // Determines is if Sematic Version A is less than Semantic Version B.
  // Semantic version must be fully specified X.Y.Z
  // * `semVerA` first semantic version
  // * `semVerB` second semantic version
  versionLessThan(semVerA, semVerB) {
    const sPartsA = semVerA.split('.');
    const sPartsB = semVerB.split('.');
    const partsA = [+sPartsA[0], +sPartsA[1], +sPartsA[2]];
    const partsB = [+sPartsB[0], +sPartsB[1], +sPartsB[2]];

    if (partsA[0] > partsB[0])
      return false;
    if (partsA[0] < partsB[0])
      return true;

    if (partsA[1] > partsB[1])
      return false;
    if (partsA[1] < partsB[1])
      return true;

    return partsA[2] < partsB[2];
  },

  // Console Logging
  // ---------------

  console: {
    /** @param {...*} m */
    log(m) {},
    /** @param {...*} m */
    info(m) {},
    /** @param {...*} m */
    warn(m) {},
    /** @param {...*} m */
    error(m) {}
  }
};

(function (console) {
  const funcs = ['log', 'info', 'warn', 'error'];

  for (const func of funcs) {
    if (console && console[func] && console[func].apply) {
      utils.console[func] = function (...args) {
        if (typeof args[0] === 'string') {
          args[0] = '[ZAIUS] ' + args[0];
          console[func](...args);
        } else {
          console[func]('[ZAIUS] ', ...args);
        }
      }
    }
  }
})(window.console);
