export const enum WebContentPredicateOperators {
  Contains = '=~',
  DoesNotContain = '!=~',
  Equals = '=',
  DoesNotEqual = '<>',
  And = 'AND',
  Or = 'OR'
}

export interface BasePredicate {
  operator: WebContentPredicateOperators;
}

export interface OrPredicate extends BasePredicate {
  operator: WebContentPredicateOperators.Or;
  predicates: WebContentPredicate[];
}

export interface AndPredicate extends BasePredicate {
  operator: WebContentPredicateOperators.And;
  predicates: WebContentPredicate[];
}

export interface ComparatorPredicate extends BasePredicate {
  operator: WebContentPredicateOperators.Contains
          | WebContentPredicateOperators.DoesNotContain
          | WebContentPredicateOperators.Equals
          | WebContentPredicateOperators.DoesNotEqual;
  field: string;
  value: string;
}

export type LogicalPredicate = AndPredicate | OrPredicate;

export type WebContentPredicate = LogicalPredicate | ComparatorPredicate;

export interface PredicateEvaluator {
  operator: WebContentPredicateOperators;
  evaluate(values: {[key: string]: string | number}): boolean;
}

export function notNullOrUndefined<T>(something: T): something is Exclude<T, null | undefined> {
  return something != null;
}

export function lower(text: string) {
  return text ? text.toLowerCase() : '';
}
