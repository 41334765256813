import {TimeUnit} from '../web/WebTriggerData';

export function timeToMilliseconds(time: number, unit: TimeUnit) {
  switch (unit) {
    case TimeUnit.Weeks:
      return time * 604800000;

    case TimeUnit.Days:
      return time * 86400000;

    case TimeUnit.Hours:
      return time * 3600000;

    case TimeUnit.Minutes:
      return time * 60000;

    default:
      return time * 1000;
  }
}

export function timeToSeconds(time: number, unit: TimeUnit) {
  return Math.round(timeToMilliseconds(time, unit) / 1000);
}
