enum Intent {
  Info,
  Warning,
  Error
}

const ANIMATION_DURATION = 500;
const MIN_HEIGHT = '40px';
const SPACING = 10;

const CLOSE_ICON = '<svg width="16" height="16" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M9.41 8l3.29-3.29c.19-.18.3-.43.3-.71a1.003 1.003 0 0 0-1.71-.71L8 6.59l-3.29-3.3a1.003 1.003 0 0 0-1.42 1.42L6.59 8 3.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 0 0 1.71.71L8 9.41l3.29 3.29c.18.19.43.3.71.3a1.003 1.003 0 0 0 .71-1.71L9.41 8z"></path></svg>';
const INFO_ICON = '<svg width="16" height="16" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8,0C3.58,0,0,3.58,0,8s3.58,8,8,8s8-3.58,8-8S12.42,0,8,0z M7,3h2v2H7V3z M10,13H6v-1h1V7H6V6h3v6h1V13z"/></svg>';
const WARNING_ICON = '<svg data-icon="warning-sign" width="16" height="16" viewBox="0 0 16 16"><desc>warning-sign</desc><path d="M15.84 13.5l.01-.01-7-12-.01.01c-.17-.3-.48-.5-.85-.5s-.67.2-.85.5l-.01-.01-7 12 .01.01c-.09.15-.15.31-.15.5 0 .55.45 1 1 1h14c.55 0 1-.45 1-1 0-.19-.06-.35-.15-.5zm-6.85-.51h-2v-2h2v2zm0-3h-2v-5h2v5z" fill-rule="evenodd"></path></svg>';
const ERROR_ICON = '<svg width="16" height="16" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M7.99-0.01c-4.42,0-8,3.58-8,8s3.58,8,8,8s8-3.58,8-8S12.41-0.01,7.99-0.01z M8.99,12.99h-2v-2h2V12.99z M8.99,9.99h-2v-7h2V9.99z"/></svg>';
const STYLE_RESET = 'box-sizing: border-box; font: 13px sans-serif; border: 0; pointer-events: all;';

const PORTAL_STYLES =
  STYLE_RESET +
  `position: fixed; top: 0; left: 0; right: 0; width: 100%; overflow: visible; z-index: 2000000001;` +
  'display: flex; flex-flow: column nowrap; align-items: center; justify-content: center;';

const CONTAINER_STYLES =
  STYLE_RESET +
  'display: flex; align-items: center; justify-content: center; overflow: hidden;' +
  'border-radius: 2px; padding-left: 10px; min-width: 300px; max-width: 700px;' +
  'box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);' +
  `transition: opacity .5s linear, margin-top .5s ease-out; opacity: 0; margin-top: -${MIN_HEIGHT}; min-height: ${MIN_HEIGHT};`;

const MESSAGE_STYLES = STYLE_RESET + 'padding: 10px; flex-grow: 1; line-height: 16px; font-weight: 300;';

const CLOSE_BUTTON_STYLES = STYLE_RESET + 'padding: 10px; cursor: pointer;';

const INTENT_BG_COLOR = {
  [Intent.Info]: '#fefefe',
  [Intent.Warning]: '#f9972d;',
  [Intent.Error]: '#f8564e'
};

const INTENT_TEXT_COLOR = {
  [Intent.Info]: '#555',
  [Intent.Warning]: '#fff',
  [Intent.Error]: '#fff',
};

const INTENT_ICON = {
  [Intent.Info]: INFO_ICON,
  [Intent.Warning]: WARNING_ICON,
  [Intent.Error]: ERROR_ICON,
};

class Toaster {
  private portal!: HTMLDivElement;

  public info(message: string) {
    this.createToast(Intent.Info, message);
  }

  public error(message: string) {
    this.createToast(Intent.Error, message);
  }

  public warning(message: string) {
    this.createToast(Intent.Warning, message);
  }

  private createToast(intent: Intent, message: string) {
    const container = document.createElement('DIV') as HTMLDivElement;
    container.setAttribute('style', CONTAINER_STYLES + `background: ${INTENT_BG_COLOR[intent]};`);

    const icon = document.createElement('DIV');
    icon.innerHTML = INTENT_ICON[intent];
    (icon.firstChild as HTMLObjectElement).style.fill = INTENT_TEXT_COLOR[intent];

    const messageDiv = document.createElement('DIV') as HTMLDivElement;
    messageDiv.setAttribute('style', MESSAGE_STYLES + `color: ${INTENT_TEXT_COLOR[intent]}`);
    messageDiv.innerHTML = message;

    const closeButton = document.createElement('DIV') as HTMLDivElement;
    closeButton.innerHTML = CLOSE_ICON;
    (closeButton.firstChild as HTMLObjectElement).style.fill = INTENT_TEXT_COLOR[intent];
    closeButton.setAttribute('style', CLOSE_BUTTON_STYLES);

    container.appendChild(icon);
    container.appendChild(messageDiv);
    container.appendChild(closeButton);

    this.showToast(container, closeButton);
  }

  private showToast(container: HTMLDivElement, closeButton: HTMLDivElement) {
    if (!this.portal) {
      this.portal = document.createElement('DIV') as HTMLDivElement;
      this.portal.setAttribute('id', 'ZaiusToasts');
      this.portal.setAttribute('style', PORTAL_STYLES);
      document.body.appendChild(this.portal);
    }

    this.portal.insertBefore(container, this.portal.firstChild);

    setTimeout(() => this.animateIn(container), 0);

    closeButton.onclick = () => this.animateOut(container);
  }

  private animateIn(container: HTMLDivElement) {
    container.style.marginTop = `${SPACING}px`;
    container.style.opacity = '1';
  }

  private animateOut(container: HTMLDivElement) {
    container.style.marginTop = '0';
    container.style.opacity = '0';
    setTimeout(() => this.remove(container), ANIMATION_DURATION);
  }

  private remove(container: HTMLDivElement) {
    this.portal.removeChild(container);
  }
}

export const toaster = new Toaster();
