export enum FieldRequirement {
  Object = 'object',
  Array = 'array',
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Function = 'function',
  Required = 'Required',
  NotEmptyString = 'NotEmptyString',
  StringOrNumber = 'StringOrNumber'
}

export const REQUIRED_STRING = [FieldRequirement.String, FieldRequirement.Required, FieldRequirement.NotEmptyString];
export const REQUIRED_OBJECT = [FieldRequirement.Object, FieldRequirement.Required];
export const REQUIRED_ARRAY = [FieldRequirement.Array, FieldRequirement.Required];
export const REQUIRED_NUMBER = [FieldRequirement.Number, FieldRequirement.Required];
export const REQUIRED_BOOLEAN = [FieldRequirement.Boolean, FieldRequirement.Required];
export const REQUIRED_STRING_OR_NUMBER = [FieldRequirement.StringOrNumber, FieldRequirement.Required];

export interface HashSchema<T = any> {
  // This is used by the validateData method to do some black magic and allow us to infer
  hashSchema_validateAs?: T;
  type: FieldRequirement | FieldRequirement[];
  properties?: {
    [field: string]: FieldRequirement | FieldRequirement[] | HashSchema<any>
  };
  all?: HashSchema<any>;
  oneOf?: any[];
}

const ROOT_NAME = 'value';

// returns true if data matches schema, throws an error otherwise
export function validateData<T = [never], TSchema extends HashSchema = HashSchema, TValidateAs = NonNullable<TSchema['hashSchema_validateAs']>>(
  data: any,
  schema: TSchema,
  path: string[] = []
): data is T extends [never] ? TValidateAs : T {
  validateField(data, Array.isArray(schema.type) ? schema.type as any : [schema.type], path);
  if (data == null) { return true; }

  if (schema.all) {
    validateAll(data, schema.all, path);
  }

  if (schema.oneOf) {
    validateOneOf(data, schema.oneOf, path);
  }

  const props = schema.properties;
  if (props) {
    for (const field in props) {
      if (!props.hasOwnProperty(field)) { continue; }

      if (Array.isArray(props[field]) || typeof props[field] === 'string') {
        const r = props[field] as FieldRequirement;
        const requirements = Array.isArray(r) ? r as any as FieldRequirement[] : [r];
        validateField(data[field], requirements, path.concat(field));
      } else {
        validateData(data[field], props[field] as HashSchema, path.concat(field));
      }
    }
  }

  return true;
}

function validateField(value: any, requirements: FieldRequirement[], path: string[]) {
  for (const requirement of requirements) {
    switch (requirement) {
      case FieldRequirement.Object:
        if (value != null && (typeof value !== 'object' || Array.isArray(value))) {
          throw new Error(`${path.join('.') || ROOT_NAME} is not an object`);
        }
        break;

      case FieldRequirement.String:
      case FieldRequirement.Number:
      case FieldRequirement.Function:
        if (value != null && typeof value !== requirement) {
          throw new Error(`${path.join('.') || ROOT_NAME} is not a ${requirement}`);
        }
        break;

      case FieldRequirement.StringOrNumber:
        if (value != null && typeof value !== 'string' && typeof value !== 'number') {
          throw new Error(`${path.join('.') || ROOT_NAME} is not a ${requirement}`);
        }
        break;

      case FieldRequirement.Array:
        if (value != null && !Array.isArray(value)) {
          throw new Error(`${path.join('.') || ROOT_NAME} is not an array`);
        }
        break;

      case FieldRequirement.Boolean:
        if (value != null && value !== true && value !== false) {
          throw new Error(`${path.join('.') || ROOT_NAME} is not a boolean`);
        }
        break;

      case FieldRequirement.Required:
        if (value == null) {
          throw new Error(`${path.join('.') || ROOT_NAME} is required`);
        }
        break;

      case FieldRequirement.NotEmptyString:
        if (typeof value === 'string' && value.trim() === '') {
          throw new Error(`${path.join('.') || ROOT_NAME} cannot be empty`);
        }
        break;
    }
  }
}

function validateAll(value: any[] | {[key: string]: any}, schema: HashSchema, path: string[]) {
  if (Array.isArray(value)) {
    const newPath = path.concat();
    const tail = newPath.pop() || 'value';
    for (let i = 0; i < value.length; i++) {
      validateData(value[i], schema, newPath.concat(`${tail}[${i}]`));
    }
  } else if (typeof value === 'object') {
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        validateData(value[key], schema, path.concat(key));
      }
    }
  } else {
    throw new Error(`${path.join('.') || ROOT_NAME} is not iterable`);
  }
}

function validateOneOf(value: any, options: any[], path: string[]) {
  if (!options.includes(value)) {
    throw new Error(`${path.join('.') || ROOT_NAME} must be one of: '${options.join(', ')}'`);
  }
}
