import tracker from '../../tracker';
import {utils} from '../../utils';

export interface ConsentData {
  identifier_value: string;
  consent?: boolean;
  identifier_field_name?: string;
  update_reason?: string;
  update_ts?: number;
  event_data?: {};
}

export class ConsentService {
  public static DEFAULT_ERROR_MESSAGE = 'Failed to send consent to Zaius. Please disable any ad or traffic blocker plugins.';

  public static updateConsent(consentObject: ConsentData, onSuccess?: () => void, onError?: (msg?: string) => void): Promise<string | void> {
    const [logSuccess, logError] = ConsentService.getLoggers(onSuccess, onError);
    const [event, errorMessage] = ConsentService.buildEvent(consentObject);

    if (errorMessage) {
      logError(errorMessage);
      return Promise.reject(errorMessage);
    }

    return tracker.event('consent', event)
      .then(() => logSuccess())
      .catch(() => {
        logError(this.DEFAULT_ERROR_MESSAGE);
        return Promise.reject(this.DEFAULT_ERROR_MESSAGE);
      });
  }

  private static buildEvent(consentObject: ConsentData): ReturnTupple<{}, string> {
    let event: any = {};
    if (consentObject == null || !utils.isObject(consentObject)) {
      return [event, 'passed argument must be an object'];
    }

    if (consentObject.consent != null) {
      if (!utils.isBoolean(consentObject.consent)) {
        return [event, '"consent" must be a boolean value'];
      }
      event.action = consentObject.consent ? 'opt-in' : 'opt-out';
    } else {
      event.action = 'opt-in';
    }

    if (consentObject.identifier_value == null) {
      return [event, '"identifier_value" is a required value'];
    } else {
      if (!utils.isString(consentObject.identifier_value) || utils.trimString(consentObject.identifier_value) === '') {
        return [event, '"identifier_value" must be a non empty string value'];
      }
    }

    if (consentObject.identifier_field_name != null) {
      if (!utils.isString(consentObject.identifier_field_name) || utils.trimString(consentObject.identifier_field_name) === '') {
        return [event, '"identifier_field_name" must be a non empty string value'];
      }
      event[utils.trimString(consentObject.identifier_field_name)] = utils.trimString(consentObject.identifier_value);
    } else {
      event.email = utils.trimString(consentObject.identifier_value);
    }

    if (event.email != null) {
      if (!utils.isValidEmail(event.email)) {
        return [event, '"identifier_value" must be valid email when the "identifier_field_name" is email'];
      }
    }

    if (consentObject.update_reason != null) {
      if (!utils.isString(consentObject.update_reason)) {
        return [event, '"update_reason" must be a string value'];
      }
      event.consent_update_reason = consentObject.update_reason;
    }

    if (consentObject.update_ts != null) {
      if (!utils.isInteger(consentObject.update_ts)) {
        return [event, '"update_ts" must be an integer representing the seconds from the epoch'];
      }
      event.ts = consentObject.update_ts;
    }

    if (consentObject.event_data != null) {
      if (!utils.isObject(consentObject.event_data)) {
        return [event, '"event_data" must be an object'];
      }
      event = utils.mixin(event, utils.compact(consentObject.event_data));
    }

    event.active_event = true;

    return [event];
  }

  private static getLoggers(onSuccess?: () => void, onError?: (msg?: string) => void) {
    const logSuccess = () => {
      if (onSuccess && utils.isFunction(onSuccess)) {
        onSuccess();
      }
    };

    const logError = (msg: string) => {
      if (onError && utils.isFunction(onError)) {
        onError(msg);
      }
    };

    return [logSuccess, logError] as const;
  }
}
