import {debounced} from '../lib/Debounce';
import {WebContentUtilities} from '../lib/WebContentUtilities';
import {tracker} from '../tracker';
import {utils} from '../utils';
import {EventAction} from '../Zaius';
import ContentLoader from './ContentLoader';
import {ContentManagerBase} from './ContentManagerBase';
import {ModalMaker} from './ModalMaker';
import {
  ShowWebContentOptions, WebContentPosition
} from './ShowWebContentOptions';
import {StaticContext} from './static-context/StaticContext';
import {isBoundsInViewPort} from './viewport';
import {WebContent} from './WebContent';
import {RenderContextData, WebContentInterface} from './WebContentInterface';
import {ContentSection, ZedWebData} from './ZedWebData';

const DATA_MAX_AGE = 365;

export class WebContentManager extends ContentManagerBase {
  protected webData!: ZedWebData;
  protected previewContext?: StaticContext;
  private frame: HTMLIFrameElement = document.createElement('iframe');
  protected get contentWindow() {
    return this.frame.contentWindow!;
  }
  private messageInterface?: WebContentInterface;
  private whichContent: ContentSection;
  private impressionIntervalId: any;
  private modalMaker?: ModalMaker;
  private trackExitEvent: boolean = true;
  private calculatedSize = {
    width: 0,
    height: 0
  };
  private onWindowResize = debounced(100, 100)(() => {
    if (this.frame.contentDocument) {
      const {width, height} = this.calculatedSize;
      if (this.webData.config.content.type === 'web_modal' && this.modalMaker) {
        this.modalMaker.updateSize(this.frame, width, height);
      } else if (this.webData.config.content.type === 'web_embed') {
        // zero width and height allow the frame to resize to the available space without limit
        WebContentUtilities.updateSize(this.frame, 0, 0, this.webData);
      }
    }
  });

  constructor(private readonly options: ShowWebContentOptions) {
    super();
    this.frame = document.createElement('IFRAME') as HTMLIFrameElement;
    this.frame.frameBorder = '0';
    this.frame.style.border = 'none';
    this.frame.style.width = '100%';
    this.frame.style.maxWidth = 'none';
    this.frame.style.display = 'none';
    this.frame.setAttribute('scrolling', 'no');
    this.frame.setAttribute('id', 'zaius-' + this.options.contentId);
    this.frame.classList.add('zaius-web-content');
    this.frame.title = 'Optimizely Web Content';
    this.whichContent = ContentSection.Main;

    if (this.isAllowedToShow()) {
      this.execute();
    }
  }

  public contentReady = () => {
    if (this.isWebModal()) {
      this.modalMaker!.beforeShow();
      this.waitForImages(() => this.modalMaker!.showModal(this.frame));
      // preload any images on the confirmation screen as well, but we don't need to wait for these
      WebContentUtilities.preloadImages(this.webData.confirmation);
    } else {
      this.frame.style.display = '';
    }
  };

  public reportError = (message: string) => {
    message = `[Web Content] ${message}`;
    if (this.options && typeof this.options.onError === 'function') {
      this.options.onError(message);
    } else {
      utils.console.error(message);
    }
  };

  public execute() {
    if (!this.isWebModal()) {
      if (!document.querySelector(this.options.target.selector)) {
        return this.reportError(`Selector not found: ${this.options.target.selector}`);
      }
    }

    // if there is a preview content use it instead of fetching
    if (this.options.previewContent) {
      this.webData = this.options.previewContent;
      this.previewContext = this.options.previewContext;
      this.displayContent();
      return;
    }

    ContentLoader.load(this.options.contentId)
      .then((content: ZedWebData) => {
        this.webData = content;
        this.displayContent();
      })
      .catch(this.reportError);
  }

  public onSizeChange = (newSize: {width: number, height: number}) => {
    this.calculatedSize = newSize;
    if (this.modalMaker) {
      this.modalMaker.updateSize(this.frame, newSize.width, newSize.height);
    } else {
      WebContentUtilities.updateSize(this.frame, newSize.width, newSize.height, this.webData);
    }
  };

  public showConfirmation() {
    if (this.webData.confirmation) {
      this.whichContent = ContentSection.Confirmation;
      this.getRenderContextData().then((renderContextData) => {
        this.writeContent(this.webData.confirmation, renderContextData);
      }).catch(this.frameLoadFailed);
    } else {
      this.reportError('Thanks for submitting!');
      this.frame.remove();
    }
  }

  public afterSubmit() {
    this.trackExitEvent = false;

    this.trackSubmission();

    if (this.options.afterSubmit) {
      this.options.afterSubmit();
    }
  }

  private destroyIframe() {
    if (this.frame) {
      this.frame.onload = null;
      this.frame.remove();
      // @ts-ignore
      delete this.frame;
    }
  }

  public destroy() {
    clearInterval(this.impressionIntervalId);
    if (this.modalMaker) {
      this.modalMaker.hideModal().then(() => {
        if (this.modalMaker) {
          this.modalMaker.destroy();
          delete this.modalMaker;
          this.destroyIframe();
        }
        window.removeEventListener('resize', this.onWindowResize);
      }).catch(utils.console.error);
    } else {
      this.destroyIframe();
    }
  }

  public beforeRedirect() {
    this.trackExitEvent = false;
  }

  private isWebModal() {
    return this.options.target.position === WebContentPosition.Modal;
  }

  private isAllowedToShow() {
    return !this.isWebModal() || !WebContent.hasShownModal();
  }

  public maybeSendExitEvent = () => {
    window.removeEventListener('resize', this.onWindowResize);
    if (this.trackExitEvent) {
      this.fireWebEvent(this.webMode, {action: EventAction.Exit});
    }
  };

  private trackSubmission = () => {
    if (WebContent.isPreviewing()) {
      return;
    }

    const submittedWebContents = this.getSubmittedWebContents();
    const contentId = this.webData.config.content.id;

    if (!submittedWebContents.includes(contentId)) {
      submittedWebContents.push(contentId);
      this.setSubmittedWebContents(submittedWebContents);
    }
  };

  private displayContent() {
    const submittedWebContents = this.getSubmittedWebContents();
    const contentId = this.webData.config.content.id;
    const contentWasSubmitted = submittedWebContents.includes(contentId);
    const shouldSkipContent = contentWasSubmitted && !this.options.displayAlways;

    if (!WebContent.isPreviewing() && shouldSkipContent) {
      return;
    }
    this.getRenderContextData().then((renderContextData) => {
      if (this.isWebModal()) {
        this.displayModalContent(renderContextData);
      } else {
        this.displayEmbedContent(renderContextData);
      }
    }).catch(this.frameLoadFailed);
  }

  private displayModalContent(renderContextData: RenderContextData) {
    if (!WebContent.hasShownModal()) {
      this.modalMaker = new ModalMaker(this.webData, this.maybeSendExitEvent);
      this.modalMaker.addModal(this.frame);
      this.writeContent(this.webData.main, renderContextData);
      this.frameLoad.then(() => {
        window.addEventListener('resize', this.onWindowResize, {passive: true});
        this.frameLoad.then(() => {
          WebContent.hasShownModal(true);
          this.options.onSuccess?.();
        });
      });
    }
  }

  private getSubmittedWebContents() {
    let submittedWebContentString;

    if (typeof window.localStorage === 'undefined') {
      submittedWebContentString = utils.getCookie('submittedWebContents');
    } else {
      submittedWebContentString = window.localStorage.getItem('submittedWebContents');
    }

    try {
      return submittedWebContentString ? JSON.parse(submittedWebContentString) : [];
    } catch (e) {
      return [];
    }
  }

  private setSubmittedWebContents(submittedWebContents: number[]) {
    const data = JSON.stringify(submittedWebContents);

    if (typeof window.localStorage === 'undefined') {
      utils.setCookie('submittedWebContents', data, DATA_MAX_AGE);
    } else {
      window.localStorage.setItem('submittedWebContents', data);
    }
  }

  private displayEmbedContent(renderContextData: RenderContextData) {
    const {selector, position} = this.options.target;
    const target = document.querySelector(selector);
    if (!target) {
      return this.reportError(`Failed to locate selector: ${selector}`);
    }

    switch (position) {
      case WebContentPosition.After:
        if (target.nextSibling) {
          target.parentNode!.insertBefore(this.frame, target.nextSibling);
        } else {
          target.parentNode!.appendChild(this.frame);
        }
        break;

      case WebContentPosition.Before:
        target.parentNode!.insertBefore(this.frame, target);
        break;

      case WebContentPosition.Inside:
        target.appendChild(this.frame);
        break;

      case WebContentPosition.Replace:
        if (!target.parentNode || target.nodeName.toUpperCase() === 'BODY') {
          const range = document.createRange();
          range.selectNodeContents(target);
          range.deleteContents();
          target.appendChild(this.frame);
        } else {
          target.parentNode.replaceChild(this.frame, target);
        }
        break;
    }

    window.addEventListener('resize', this.onWindowResize, {passive: true});
    this.writeContent(this.webData.main, renderContextData);
  }

  private onFrameLoad = () => {
    this.messageInterface = new WebContentInterface(this, this.frame.contentWindow!);
    this.frameLoaded();
  };

  private writeContent(content: string, renderContextData: RenderContextData) {
    this.frame.onload = this.onFrameLoad;
    this.frameLoad = new Promise<void>(((resolve, reject) => {
      this.frameLoaded = resolve;
      this.frameLoadFailed = reject;
    }));

    if (WebContent.isSrcDocSupported()) {
      this.writeSrcDoc(content);
    } else {
      this.writeContentWindow(content);
    }
    this.renderContent(renderContextData);
  }

  private writeContentWindow(content: string) {
    // In Safari 16.5 js scrips are not executing in a background thus no
    // event is sent from frame to main page.
    // if we ever find this to be the way to go we need to make sure it executes scripts
    this.frame.src = 'about:blank';
    this.frame.contentWindow!.document.open();
    this.frame.contentWindow!.document.write(content);
    if (this.webData.script) {
      const scriptEl = this.frame.contentWindow!.document.createElement('script');
      scriptEl.innerHTML = this.webData.script;
      this.frame.contentWindow!.document.head.insertBefore(
        scriptEl,
        this.frame.contentWindow!.document.head.children.item(0)
      );
    }
    this.frame.contentWindow!.document.close();
  }

  private writeSrcDoc(content: string) {
    this.frame.srcdoc = this.webData.script ?
      content.replace('<html><head>', `<html><head><script>${this.webData.script}</script>`) :
      content;
  }

  private renderContent({gridData, staticContext}: RenderContextData) {
    // utils.mixin isn't well enough typed to avoid the any here.
    let variables: any = {};
    if (gridData) {
      gridData.forEach((grid) => {
        variables[`__grid_${grid.grid_id}`] = grid.data;
      });
    }
    variables = utils.mixin(variables, staticContext, this.options.renderVariables ?? {});

    const payload = {
      user: {email: tracker.getEmail()},
      config: this.webData.config,
      render_context: {
        variables
      }
    };

    return this.frameLoad.then(() => {
      this.messageInterface!.initialize(payload);

      if (this.whichContent === ContentSection.Main) {
        this.impressionIntervalId = setInterval(() => {
          if (isBoundsInViewPort(this.frame)) {
            this.recordImpression();
            clearInterval(this.impressionIntervalId);
          }
        }, 500);
      }

      if (this.isWebModal()) {
        this.onSizeChange({width: 0, height: 0});
      }
    });
  }

  private waitForImages(callback: () => void) {
    let complete = false;
    const onComplete = () => {
      if (!complete) {
        complete = true;
        callback();
      }
    };
    // wait no more than 5 seconds for images to load
    setTimeout(onComplete, 5000);

    try {
      let required = 0;
      const onLoad = () => {
        if (--required === 0) {
          onComplete();
        }
      };

      const images = Array.from(this.frame.contentDocument!.images);
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        if (!image.complete) {
          required++;
          image.addEventListener('load', onLoad, false);
        }
      }

      if (required === 0) {
        onComplete();
      }
    } catch (e) {
      console.log(e);
      onComplete();
    }
  }
}
