export function browserName(ua: string) {
  if (ua.indexOf('Edge') !== -1 || ua.indexOf('Edg') !== -1) {
    return 'edge';
  }
  if (ua.indexOf('MSIE') !== -1 || ua.indexOf('Trident') !== -1) {
    return 'ie';
  }
  if (ua.indexOf('Opera') !== -1 || ua.indexOf('OPR/') !== -1) {
    return 'opera';
  }
  if (ua.indexOf('Firefox') !== -1) {
    return 'firefox';
  }
  if (ua.indexOf('Chrome') !== -1) {
    return 'chrome';
  }
  if (ua.indexOf('Safari') !== -1) {
    return 'safari';
  }
  return 'other';
}
