import tracker from './tracker';

type Tracker = typeof tracker;
export interface ZaiusSDK extends Tracker {
  config: ZaiusSDKConfig;
}

export interface ZaiusSDKConfig {
  trackerUrl: string;
  publicApiUrl: string;
  visitorCookieExpiryDays: number;
  referrerCookieExpiryDays: number;
  cookieDomain: string;
  idSyncs: unknown;
  includeQueryString: boolean;
  includeHash: boolean;
  convertParameters: boolean;
  realtimeSegments: {
    enabled: boolean;
    maxRecentEvents: number;
    recentEventsTTL: number;
  };
  webPushConfigs: unknown;
  webContent: {
    enabled: boolean;
    source: string;
  };
}

export type ZaiusEventField = string | number | boolean | {[key: string]: ZaiusEventField};
export interface ZaiusEventData {
  [field: string]: ZaiusEventField | undefined;
}

export interface ZaiusEvent {
  type: string;
  data: ZaiusEventData;
}

export enum EventAction {
  Click = 'click',
  Exit = 'exit',
  Impression = 'impression',
  Unsubscribe = 'unsubscribe'
}
