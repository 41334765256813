import {PredicateEvaluator, notNullOrUndefined, WebContentPredicateOperators, WebContentPredicate} from './PredicateCommon';
import {unmarshalPredicate} from './PageDisplayConstraints';

export class OrPredicateEvaluator implements PredicateEvaluator {
  private predicates: PredicateEvaluator[];
  constructor(public operator: WebContentPredicateOperators, predicates: WebContentPredicate[]) {
    this.predicates = predicates.map(unmarshalPredicate).filter(notNullOrUndefined);
  }
  public evaluate(values: {[key: string]: string | number}) {
    return this.predicates.some((predicate) => predicate.evaluate(values));
  }
}
