import {TimeUnit} from '../web/WebTriggerData';
import {getTimestampInSeconds} from './TimestampInSeconds';
import {timeToSeconds} from './TimeToMilliseconds';

interface ProductViews {
  [productId: string]: number;
}

export const enum ProductRecommendationSourceType {
  PRODUCTS_ON_PAGE = 'products_on_page',
  RECENT_BROWSE_HISTORY = 'recent_browse_history'
}

export const enum ProductRecommendationSources {
  ZAIUS_RECOMMENDATIONS = '__zaius_model_recs__',
  BEST_SELLERS = 'zaius_recommendations_best_sellers'
}

export class RecentlyViewedProducts {
  private static readonly KEY = 'zRVP';

  private static readonly TTL = timeToSeconds(2, TimeUnit.Weeks);

  private static productsOnPage: string[] = [];

  public static updateProductView(productId: string) {
    const productView: ProductViews = {
      [productId]: getTimestampInSeconds()
    };

    const productsViews = this.getRecentlyBrowsedProducts();
    this.productsOnPage = [...this.productsOnPage, productId];
    let productViews = {...productsViews, ...productView};
    productViews = Object.entries(productViews)
      .sort(([_productId1, viewedAt1], [_productId2, viewedAt2]) => viewedAt1 > viewedAt2 ? -1 : 1)
      .slice(0, 3)
      .reduce((acc, [itemProductId, viewedAt]) => {
        acc[itemProductId] = viewedAt;
        return acc;
      }, {} as ProductViews);
    return this.updateProductViews(productViews);
  }

  public static getIds(): string[] {
    return Object.keys(this.getRecentlyBrowsedProducts());
  }

  public static includes(productId: string): boolean {
    return this.getIds().includes(productId);
  }

  public static getRecentlyBrowsedProducts(): ProductViews {
    const productsString = localStorage.getItem(this.KEY);
    return productsString ? JSON.parse(productsString) : {};
  }

  public static getIdsOnPage(): string[] {
    return this.productsOnPage;
  }

  public static removeStaleProducts() {
    const currentTimeInSeconds = getTimestampInSeconds();
    const productViews = this.getRecentlyBrowsedProducts();
    Object.entries(productViews).forEach(([productId, viewedTime]) => {
      if (currentTimeInSeconds - viewedTime > this.TTL) {
        delete productViews[productId];
      }
    });

    this.updateProductViews(productViews);
  }

  public static clearProducts() {
    localStorage.removeItem(this.KEY);
  }

  private static updateProductViews(productViews: ProductViews) {
    localStorage.setItem(this.KEY, JSON.stringify(productViews));
    return productViews;
  }
}
