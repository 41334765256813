import {utils} from './utils';

// Search Referrer Utils
// ---------------------

export const search = {
  // Registered Providers
  // --------------------

  PROVIDER_PATTERN: /^(.*\.)?(daum|eniro|naver|google|googleadservices|yahoo|msn|bing|aol|lycos|ask|altavista|netscape|cnn|about|mamma|alltheweb|voila|virgilio|baidu|alice|yandex|najdi|seznam|search|wp|onetcenter|szukacz|yam|pchome|kvasir|sesam|ozu|terra|mynet|ekolay|rambler)\.[^\.]{2,3}(\.[^\.]{2})?$/,

  PROVIDER_PARAMS: {
    'daum': ['q'],
    'eniro': ['search_word'],
    'naver': ['query'],
    'google': ['q'],
    'googleadservices': ['q'],
    'yahoo': ['p'],
    'msn': ['q'],
    'bing': ['q'],
    'aol': ['query', 'q'],
    'lycos': ['query'],
    'ask': ['q'],
    'altavista': ['q'],
    'netscape': ['query'],
    'cnn': ['query'],
    'about': ['terms'],
    'mamma': ['query'],
    'alltheweb': ['q'],
    'voila': ['rdata'],
    'virgilio': ['qs'],
    'baidu': ['wd'],
    'alice': ['qs'],
    'yandex': ['text'],
    'najdi': ['q'],
    'seznam': ['q'],
    'search': ['q'],
    'wp': ['szukaj'],
    'onetcenter': ['qt'],
    'szukacz': ['q'],
    'yam': ['k'],
    'pchome': ['q'],
    'kvasir': ['q'],
    'sesam': ['q'],
    'ozu': ['q'],
    'terra': ['query'],
    'mynet': ['q'],
    'ekolay': ['q'],
    'rambler': ['words']
  },

  // Meta
  // ----

  // If the referrer is a search provider return an object containing
  // the source (registered name from search.providers), the medium (
  // source or organic), and the keywords from the search.
  //
  meta(referrer) {
    if (!this.searchFields) {
      const providerMatch = referrer.hostname.match(this.PROVIDER_PATTERN);
      if (providerMatch && providerMatch[2]) {
        let provider = providerMatch[2];
        const params = this.PROVIDER_PARAMS[provider];
        let keywords;
        for (let i = 0; i < params.length; i++) {
          const match = referrer.queryString.match(new RegExp('[\\?&]' + params[i] + '=([^&]*)'));
          if (match) {
            keywords = match[1];
            break;
          }
        }
        let originalReferral;
        let medium = 'organic';
        if (provider == 'google' || provider == 'googleadservices') {
          const pathParts = referrer.path.split('/');
          if (pathParts[pathParts.length - 1] == 'aclk') {
            medium = 'cpc';
            if (provider == 'googleadservices') {
              provider = 'google';
              const originalHost = referrer.queryString.match(/[\?&]ohost=([^&]*)/);
              if (originalHost && originalHost[1]) {
                originalReferral = {
                  'referrer': originalHost[1],
                  'referral_host': originalHost[1],
                  'referral_path': ''
                };
              }
            }
          }
        }
        this.searchFields = utils.compact({
          'source': provider,
          'medium': medium,
          'keywords': keywords,
          'original_referral': originalReferral
        });
      }
    }
    return this.searchFields;
  }
};
