import {browserName} from '../lib/browserName';
import tracker from '../tracker';
import {ZaiusEventData} from '../Zaius';
import {resolveWebPushReady, webPush} from './initialize';
import pushOptIn from './optin';
import {LegacyChromePlatform, WebPushConfig, WebPushPlatform} from './WebPushConfig';

let pushConfig: WebPushConfig;
let platformConfig: WebPushPlatform | undefined;
let legacyChromeConfig: LegacyChromePlatform | undefined;
const webPusher = {
  unsubscribe() {
    return navigator.serviceWorker.register((platformConfig?.serviceBase || '/') + 'zaius-notification-service.js')
      .then((serviceWorkerRegistration) => {
        serviceWorkerRegistration.pushManager.getSubscription()
          .then((subscription) => {
            if (!subscription) {
              // no subscription found to unsubscribe from
              return;
            }
            const token = webPusher.encodedToken(subscription);
            subscription.unsubscribe()
              .then(() => {
                const event = {
                  action: 'remove_push_token',
                  app_id: pushConfig.websiteID
                } as ZaiusEventData;
                event[webPusher.tokenFieldName()] = token;
                tracker.event('push', event);
              }).catch((e) => {
                console.log('error unsubcribing', e);
              });
          }).catch((e) => {
            console.log('error fetching push notification subscription', e);
          });
      });
  },

  subscribe() {
    return navigator.serviceWorker.register((platformConfig?.serviceBase || '/') + 'zaius-notification-service.js')
      .then((serviceWorkerRegistration) => {
        return serviceWorkerRegistration.pushManager.subscribe({userVisibleOnly: true, applicationServerKey: webPusher.vapidPublic})
          .then((subscription) => {
            const token = webPusher.encodedToken(subscription);
            const event = {
              action: 'add_push_token',
              app_id: pushConfig.websiteID
            } as ZaiusEventData;
            event[webPusher.tokenFieldName()] = token;
            tracker.event('push', event);
            tracker.updateWebPushToken('web', pushConfig.websiteID, token, this.tokenFieldName());
            return 'subscribed';
          })
          .catch((e) => {
            if (Notification.permission === 'denied') {
              // user said no when prompted for permission
              tracker.updateWebPushToken('web', pushConfig.websiteID, null);
              return 'denied';
            } else {
              console.log('error subscribing for push notifications', e);
              return Promise.reject(e);
            }
          });
      });
  },

  isSubscriptionCurrent(subscription: PushSubscription) {
    if (subscription?.options?.applicationServerKey) {
      if (webPusher.vapidPublic) {
        return webPusher.arrayToBase64(subscription.options.applicationServerKey) === webPusher.vapidPublic;
      } else if (legacyChromeConfig?.senderID) {
        return String.fromCharCode(...new Uint8Array(subscription.options.applicationServerKey)) === legacyChromeConfig.senderID;
      }
    }
    return !tracker.getWebPushToken() || tracker.getWebPushToken()?.token === webPusher.encodedToken(subscription);
  },

  initialize(webPushConfig: WebPushConfig, _registration?: ServiceWorkerRegistration) {
    pushConfig = webPushConfig;
    platformConfig = webPushConfig.platforms.web;
    legacyChromeConfig = webPushConfig.platforms.chrome;
    if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
      // notifications are not supported in service worker
      return;
    }

    if (Notification.permission === 'denied') {
      tracker.updateWebPushToken('web', pushConfig.websiteID, null);
      return;
    }

    if (!('PushManager' in window)) {
      // push notifications are not supported
      return;
    }

    if (legacyChromeConfig?.autoManifest) {
      const ref = document.getElementsByTagName('link')[0] || document.getElementsByTagName('title')[0];
      if (ref) {
        const link = document.createElement('link');
        link.rel = 'manifest';
        link.href = (webPusher.serviceBase || '/') + 'manifest.json';
        ref.parentNode?.insertBefore(link, ref);
      }
    }

    navigator.serviceWorker.register((platformConfig?.serviceBase || '/') + 'zaius-notification-service.js')
      .then((serviceWorkerRegistration) => {
        serviceWorkerRegistration.pushManager.getSubscription()
          .then((subscription) => {
            if (!subscription) {
              resolveWebPushReady(Notification.permission); // see initialize.js
              if (Notification.permission === 'default') {
                tracker.updateWebPushToken('web', pushConfig.websiteID, null);
                pushOptIn.showOptIn(webPush.askToSubscribe, pushConfig);
              } else if (Notification.permission === 'denied') {
                tracker.updateWebPushToken('web', pushConfig.websiteID, null);
              } else {
                webPusher.subscribe();
              }
            } else {
              resolveWebPushReady('granted'); // see initialize.js
              if (webPusher.isSubscriptionCurrent(subscription)) {
                const token = webPusher.encodedToken(subscription);
                tracker.updateWebPushToken('web', pushConfig.websiteID, token, this.tokenFieldName());
              } else {
                // attempt to unsubscribe first, then subscribe regardless of error or success
                subscription.unsubscribe()
                  .catch(() => null)
                  .then(webPusher.subscribe);
              }
            }
          })
          .catch((e) => {
            console.log('error fetching push notification subscription', e);
          });
      });
  },

  encodedToken(subscription: PushSubscription) {
    if (webPusher.vapidPublic) {
      const p256dh = webPusher.arrayToBase64(subscription.getKey('p256dh')!);
      const auth = webPusher.arrayToBase64(subscription.getKey('auth')!);
      const browser = browserName(navigator.userAgent);
      return `${browser}|${p256dh}|${auth}|${subscription.expirationTime || ''}|${encodeURIComponent(subscription.endpoint)}`;
    } else {
      const endpointSections = subscription.endpoint.split('/');
      return endpointSections[endpointSections.length - 1];
    }
  },

  arrayToBase64(array: ArrayBuffer) {
    const uint8Array = new Uint8Array(array);
    const base64String = btoa(String.fromCharCode(...uint8Array));
    return base64String
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
  },

  askToSubscribe() {
    return webPusher.subscribe().then((result) => {
      pushOptIn.hideOptIn();
      return result;
    });
  },

  tokenFieldName() {
    return webPusher.tokenField ?? pushConfig.websiteID + `_${browserName(navigator.userAgent)}_push_tokens`;
  },

  get tokenField() {
    return (platformConfig || legacyChromeConfig)?.tokenField;
  },

  get serviceBase() {
    return (platformConfig || legacyChromeConfig)?.serviceBase;
  },

  get vapidPublic() {
    return (platformConfig || legacyChromeConfig)?.vapidPublic;
  }
};

export default webPusher;
