import {WebContentUtilities} from '../lib/WebContentUtilities';
import {meetsFrequencyConstraints} from './constraints/FrequencyConstraints';
import {pageMatchesPredicate} from './constraints/PageDisplayConstraints';
import ContentLoader from './ContentLoader';
import {RealtimeSegmentsProxy} from './RealtimeSegmentsProxy';
import {WebContentPosition} from './ShowWebContentOptions';
import {StaticContext} from './static-context/StaticContext';
import {
  ExitIntentTrigger, ExitIntentTriggerConfig
} from './triggers/ExitIntentTrigger';
import {ScrollTrigger} from './triggers/ScrollTrigger';
import {TimerTrigger} from './triggers/TimerTrigger';
import {WebContent} from './WebContent';
import {ZedWebDefinition} from './WebTriggerData';
import {ZedWebData} from './ZedWebData';

export class WebTriggerEvaluator {
  private hasTriggered: boolean = false;
  private isEligible: boolean = false;
  private readonly exitIntentTrigger: ExitIntentTriggerConfig;
  private readonly realtimeSegments: string[] = [];

  constructor(
    private contentId: string,
    private readonly definition: ZedWebDefinition,
    private readonly previewContent?: ZedWebData,
    private readonly errorHandler?: (message: string) => void,
    private readonly staticContext?: StaticContext
  ) {
    this.realtimeSegments = definition.realtime_segments || [];
    this.exitIntentTrigger = {
      enabled: definition.triggers.exitIntent || false
    };
    this.reset();
  }

  public reset() {
    const didTrigger = this.hasTriggered;
    this.hasTriggered = false;
    const wereEligible = this.isEligible;
    this.isEligible = this.pageAllows() && this.constraintsAllow();

    if (this.isEligible) {
      if (this.definition.triggers.pageLoad) {
        this.trigger();
      } else if (!wereEligible || didTrigger) {
        this.addTriggers();
      }
    } else if (wereEligible) {
      this.removeTriggers();
    }
  }

  private pageAllows(): boolean {
    return pageMatchesPredicate(this.definition.display.predicate);
  }

  private contentPosition(): WebContentPosition | null {
    return this.definition.display.contentPosition;
  }

  private selector(): string | null {
    return this.definition.display.selector;
  }

  private constraintsAllow(): boolean {
    const {displayAlways, displayFrequency, displayLimit, showOnMobile} = this.definition.constraints;
    if (displayAlways) {
      return true;
    }

    if (!meetsFrequencyConstraints(this.contentId, displayFrequency, displayLimit)) {
      return false;
    }

    if (this.probablyMobile()) {
      return showOnMobile || this.contentPosition() !== WebContentPosition.Modal;
    } else {
      return true;
    }
  }

  private addTriggers() {
    const {triggers: {timeSpent, pageScroll, exitIntent}} = this.definition;

    if (timeSpent && timeSpent.enabled) {
      TimerTrigger.getInstance().addTrigger(timeSpent, this.trigger);
    }

    if (pageScroll && pageScroll.enabled) {
      ScrollTrigger.getInstance().addTrigger(pageScroll, this.trigger);
    }

    if (exitIntent) {
      ExitIntentTrigger.getInstance().addTrigger(this.exitIntentTrigger, this.trigger);
      // preload content for exit intent
      ContentLoader.load(this.contentId).then((content) => {
        // preload images
        WebContentUtilities.preloadImages(content.main);
      });
    }
  }

  private removeTriggers() {
    const {triggers: {timeSpent, pageScroll, exitIntent}} = this.definition;

    if (timeSpent && timeSpent.enabled) {
      TimerTrigger.getInstance().removeTrigger(timeSpent);
    }

    if (pageScroll && pageScroll.enabled) {
      ScrollTrigger.getInstance().removeTrigger(pageScroll);
    }

    if (exitIntent) {
      ExitIntentTrigger.getInstance().removeTrigger(this.exitIntentTrigger);
    }
  }

  private trigger = () => {
    if (this.isEligible && !this.hasTriggered) {
      this.hasTriggered = true;
      this.removeTriggers();

      const selector: string | null = this.selector();
      const position: WebContentPosition | null = this.contentPosition();
      const selectorVerified = position === WebContentPosition.Modal || selector != null;

      if (this.pageAllows() && selectorVerified && position != null) {
        this.ifRealtimeSegmentsAllow(() => this.showWebContent(selector, position));
      }
    }
  };

  private ifRealtimeSegmentsAllow(onSuccess: () => void) {
    RealtimeSegmentsProxy.evaluateRTS({
      realtimeSegments: this.realtimeSegments,
      onSuccess,
    });
  }

  private showWebContent(selector: string | null, position: WebContentPosition) {
    WebContent.showContent({
      contentId: this.contentId,
      target: {
        selector,
        position
      },
      displayAlways:  this.definition.constraints.displayAlways,
      previewContent: this.previewContent,
      previewContext: this.staticContext,
      onError: this.errorHandler
    });
  }

  private probablyMobile(): boolean {
    try {
      const mobileUserAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const mobileSize = window.matchMedia('only screen and (max-width: 760px)').matches;
      return mobileUserAgent || mobileSize;
    } catch (e) {
      return true;
    }
  }
}
