import tracker from '../tracker';
import {ZaiusSDK} from '../Zaius';
import {
  RealtimeSegmentsEvaluator, RealtimeSegmentsEvaluatorProps
} from './RealtimeSegmentsEvaluator';
import {EventInput, RecentEventsCollector} from './RecentEventsCollector';
import {WebContent} from './WebContent';

export const RealtimeSegmentsProxy = {
  get enabled() {
    return isRealtimeSegmentsEnabled();
  },

  initializeStorage() {
    if (this.enabled) {
      RecentEventsCollector.initialize();
    }
  },

  addRecentEvent(event: EventInput) {
    if (this.enabled) {
      RecentEventsCollector.add(event);
    }
  },

  evaluateRTS({realtimeSegments, onSuccess}: RealtimeSegmentsEvaluatorProps) {
    if (this.enabled) {
      RealtimeSegmentsEvaluator.evaluate({
        realtimeSegments,
        onSuccess,
      });
    } else {
      onSuccess();
    }
  }
};

function isRealtimeSegmentsEnabled(): boolean {
  const config = (tracker as ZaiusSDK).config;

  return !!(config.webContent.enabled && config.realtimeSegments.enabled && WebContent.isWebContentSupported());
}
