import {browserName} from '../lib/browserName';
import {tracker} from '../tracker';
import {utils} from '../utils';
import safariPusher from './safari';
import {WebPushConfig} from './WebPushConfig';
import webPusher from './webPusher';

const browser = browserName(navigator.userAgent);
const pushPlatform = browser === 'safari' ? 'safari' : (['chrome', 'firefox', 'edge'].includes(browser) ? 'web' : null);

export let resolveWebPushReady: (status: NotificationPermission| 'unknown') => void;
let https = false;
try {
  https = window.location.href.startsWith('https://');
} catch (e) { /* noop */ }

export const webPush = {
  config: null! as any,
  baseConfigs: null! as { // Added by tracker.js during initialize
    [appId: string]: WebPushConfig;
  },
  ready: new Promise<NotificationPermission | 'unknown'>((resolve) => {
    resolveWebPushReady = resolve;
  }),
  initialize(pushConfig: WebPushConfig, registration?: ServiceWorkerRegistration) {
    if (!https) {
      return;
    }

    if (!pushConfig) {
      console.log('zaius.webPush.initialize: missing required config object parameter');
      return;
    }

    if (!pushConfig.websiteID) {
      console.log('zaius.webPush.initialize: missing required field `websiteID`');
      return;
    }

    if (!pushConfig.autoInit) {
      const baseConfig = webPush.baseConfigs[pushConfig.websiteID];
      if (baseConfig) {
        const combined = utils.deepClone(baseConfig);
        combined.subscribeUI = utils.mixin(combined.subscribeUI || {}, pushConfig.subscribeUI || {});
        pushConfig = combined;
      }
    }

    webPush.config = pushConfig;
    if (pushPlatform === 'web') {
      const webConfig = pushConfig?.platforms?.web || pushConfig?.platforms?.chrome;
      if (webConfig?.enabled) {
        if (!registration) {
          navigator.serviceWorker.register((webConfig.serviceBase || '/') + 'zaius-notification-service.js')
            .then((reg) => webPusher.initialize(pushConfig, reg))
            .catch((e) => {
              console.log('error registering zaius-notification-service.js', e);
            });
        } else {
          webPusher.initialize(pushConfig, registration);
        }
      } else {
        webPush.remove();
      }
    } else if (pushPlatform === 'safari') {
      if (pushConfig?.platforms?.safari?.enabled) {
        if (pushConfig.websitePushID) {
          const data = ((window as any).safari as any).pushNotification.permission(pushConfig.websitePushID);
          safariPusher.initialize(pushConfig, data);
        } else {
          console.log('zaius.webPush.initialize: missing required field `websitePushID`');
        }
      } else {
        webPush.remove();
      }
    }
  },
  remove() {
    if (!https) {
      return;
    }

    if (pushPlatform === 'web') {
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration) {
          const worker = registration.active;
          if (worker && worker.scriptURL && worker.scriptURL.indexOf('zaius-notification-service.js') >= 0) {
            registration.unregister();
          }
        }
      });
    }
    // else if (pushPlatform === 'safari') {}
    // TODO: I would hope there's some way of removing ourselves from safari, but I don't see one. It's somewhat
    // less of an issue for safari than from chrome though, as we're at least not injecting code. Though safari will
    // continue to hit our service endpoint with registration updates and push package refreshes.
  },
  askToSubscribe(callback?: (status: NotificationPermission | 'unknown') => void) {
    if (!https) {
      return;
    }

    if (typeof callback !== 'function') {
      callback = undefined;
    }
    webPush.ready = webPush.ready.then((permission: NotificationPermission | 'unknown') => {
      if (pushPlatform) {
        const pusher = pushPlatform === 'web' ? webPusher : (pushPlatform === 'safari' ? safariPusher : null);
        if (!pusher) {
          return Promise.reject(new Error('Unknown webpush platform'));
        }
        if (permission === 'default') {
          const ask = pusher.askToSubscribe();
          if (callback) {
            ask.then(callback);
          }
          return ask;
        } else if (permission !== 'denied') {
          pusher.subscribe();
        }
      }
      if (callback) {
        callback(permission);
      }
      return;
    });
  },
  subscriptionStatus(callback: (status: NotificationPermission | 'unsupported', token?: string) => void) {
    if (typeof callback !== 'function') {
      return;
    }
    if (!https) {
      callback('unsupported');
      return;
    }
    if (pushPlatform) {
      webPush.ready.then((status) => {
        const result = tracker.getWebPushToken();
        callback(status as NotificationPermission, result?.token);
      });
    }
  }
};
