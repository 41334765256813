import {
  HashSchema, REQUIRED_BOOLEAN, REQUIRED_ARRAY, REQUIRED_OBJECT, REQUIRED_STRING,
  FieldRequirement
} from '../lib/ValidateData';

export interface Subscription {
  name: string;
  list_id: string;
  subscribed: boolean;
}
export interface ListSubscriptionData {
  customer: {
    email: string;
  };
  explicit_opt_in?: boolean;
  opted_in: boolean;
  subscriptions: Subscription[];
}

export const LIST_SUBSCRIPTION_DATA_SCHEMA: HashSchema<ListSubscriptionData> = {
  type: REQUIRED_OBJECT,
  properties: {
    customer: {
      type: REQUIRED_OBJECT,
      properties: {
        email: REQUIRED_STRING
      }
    },
    opted_in: REQUIRED_BOOLEAN,
    explicit_opt_in: FieldRequirement.Boolean,
    subscriptions: {
      type: REQUIRED_ARRAY,
      all: {
        type: REQUIRED_OBJECT,
        properties: {
          name: REQUIRED_STRING,
          list_id: REQUIRED_STRING,
          subscribed: REQUIRED_BOOLEAN,
        }
      }
    }
  }
};
