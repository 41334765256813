import {validateData} from '../lib/ValidateData';
import {tracker} from '../tracker';
import {utils} from '../utils';
import {ContentManagerBase} from './ContentManagerBase';
import {LandingPageContentManager} from './LandingPageContentManager';
import {ShowWebContentOptions, WEB_CONTENT_OPTIONS_SCHEMA} from './ShowWebContentOptions';
import {toaster} from './Toaster';
import {WebContentManager} from './WebContentManager';
import {WebPreview} from './WebPreview';
import {WebTrigger} from './WebTrigger';
import {ZED_WEB_DATA_SCHEMA} from './ZedWebData';

const webContentManagers: {[id: string]: ContentManagerBase} = {};
let nextId = 1;
let hasShownModal = false;

export const WebContent = {
  isIE() {
    return !!navigator.userAgent.match(/\bTrident\b|\bMSIE\b/i);
  },
  isSrcDocSupported() {
    return ('srcdoc' in document.createElement('iframe'));
  },
  isWebContentSupported() {
    if (this.isIE()) {
      const ie = parseInt((/msie\s(\d+)/i.exec(navigator.userAgent) || ['NaN'])[1], 10);
      if (!isNaN(ie)) {
        return ie >= 10;
      }
    }

    // Depends on API's that we use, rather than on browser environments.
    // @ts-ignore
    return window.matchMedia && window.MessageChannel;
  },
  initialize() {
    if (WebContent.isWebContentSupported()) {
      WebTrigger.watch();
      (tracker.pageviewCallbacks as Array<() => void>).push(() => WebContent.hasShownModal(false));
    } else if (WebContent.isPreviewing()) {
      WebPreview.displayError('Web Content is not currently supported on your browser. Try Edge, Chrome, Firefox, or Safari.');
    }
  },
  showContent: (options: unknown | ShowWebContentOptions) => {
    try {
      const id = nextId++;
      if (validateData(options, WEB_CONTENT_OPTIONS_SCHEMA)) {
        if (!WebContent.isWebContentSupported()) {
          throw new Error('Web Content is not currently supported on your browser');
        }
        webContentManagers[id] = new WebContentManager(options);
        return id;
      }
    } catch (error) {
      const message = `[Web Content] Error initializing content: ${error.message}`;
      if (WebContent.isPreviewing()) {
        toaster.error(message);
      }
      if (options && typeof (options as any).onError === 'function') {
        (options as any).onError(message);
      } else {
        utils.console.error(message);
      }
    }
    return false;
  },
  removeContent(id: number) {
    webContentManagers[id].destroy();
    delete webContentManagers[id];
  },
  initializeLandingPage(webConfig: unknown) {
    try {
      const id = nextId++;
      if (validateData(webConfig, ZED_WEB_DATA_SCHEMA)) {
        if (!WebContent.isWebContentSupported()) {
          throw new Error('Your browser is not supported. Try a different web browser such as Edge, Chrome, Firefox, or Safari');
        }
        webContentManagers[id] = new LandingPageContentManager(webConfig);
        return id;
      }
    } catch (error) {
      const message = `[Web Content] Error initializing Content: ${error.message}`;
      toaster.error(message);
      utils.console.error(message);
    }
    return false;
  },
  isPreviewing() {
    return !!WebContent.previewContentIdentifier();
  },
  previewContentIdentifier() {
    const matches = window.location.search.match(/[?&]zaiusWebPreview=([^&]+)/);
    return matches ? decodeURIComponent(matches[1]) : null;
  },
  previewTrackerId() {
    const matches = window.location.search.match(/[?&]previewTrackerId=([^&]+)/);
    return matches ? decodeURIComponent(matches[1]) : null;
  },
  hasShownModal(newValue?: boolean) {
    if (typeof newValue !== 'undefined') {
      hasShownModal = newValue;
    }
    return hasShownModal;
  }
};

if (SDK_TEST_MODE) {
  __internals.WebContent = {
    webContentManagers
  };
}
