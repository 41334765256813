import tracker from '../tracker';
import {utils} from '../utils';
import {ZaiusSDK} from '../Zaius';
import {
  CREATED_AT_FIELD, RecentEventsStorage, RecentEventStored
} from './RecentEventsStorage';

const FIELDS_USELESS_FOR_RTS_EVALUATION = [
  'zaius_js_version',
  'tracker_id',
  'vuid',
  'u', /* cacheBuster */
  /* zm64_id */
];

export interface EventInput {
  event_type: string;
  action?: string;
  [key: string]: any;
}

export interface RecentEvent {
  type: string;
  action?: string;
  [key: string]: any;
}

export const RecentEventsCollector = {
  get key(): string {
    return tracker.VUID!;
  },
  get config(): any {
    return (tracker as ZaiusSDK).config.realtimeSegments || {};
  },

  /* public */
  initialize() {
    this.launchGC();
  },

  launchGC() {
    window.setInterval(() => {
      const recentEvents = RecentEventsStorage.readAll(this.key);
      const freshEvents = recentEvents.filter((record) => !this.isExpired(record));
      RecentEventsStorage.update(this.key, freshEvents);
    }, this.config.recentEventsTTL);
  },

  /* public */
  add(event: EventInput) {
    const recentEvents = RecentEventsStorage.readAll(this.key);
    if (recentEvents.length >= this.config.maxRecentEvents) { recentEvents.shift(); }

    recentEvents.push(this.buildNewRecord(event));
    RecentEventsStorage.update(this.key, recentEvents);
  },

  buildNewRecord(event: EventInput): RecentEventStored {
    const newEvent = JSON.parse(JSON.stringify(event));

    newEvent.type = newEvent.event_type;
    delete newEvent.event_type;
    if (newEvent[CREATED_AT_FIELD]) {
      utils.console.warn(`The field ${CREATED_AT_FIELD} is internal, please use a different one`);
    }
    newEvent[CREATED_AT_FIELD] = Date.now();

    FIELDS_USELESS_FOR_RTS_EVALUATION.forEach((field) => delete newEvent[field]);

    return newEvent;
  },

  /* public */
  getAll(): RecentEvent[] {
    const recentEvents = RecentEventsStorage.readAll(this.key);
    recentEvents.map((record: any) => delete record[CREATED_AT_FIELD]);
    return recentEvents;
  },

  isExpired(record: RecentEvent): boolean {
    return Date.now() - record[CREATED_AT_FIELD]! > this.config.recentEventsTTL;
  }
};
