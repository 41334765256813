import {utils} from '../utils';

/** Represents a 'header' for a HTML request, typically a Key-Value.  The value can be an empty string. */
export interface RequestHeader {
  key: string;
  value: string;
}

/** Options intended for use with a JSON request, such as Headers and a URL */
export interface RequestOptions {
  url: string;
  headers?: RequestHeader[];
}

interface PostRequestOptions extends RequestOptions {
  body: {[key: string]: any};
}

interface ExtendedRequestOptions extends RequestOptions {
  url: string;
  method?: string;
  headers?: RequestHeader[];
  body?: any;
}

export function getJson(options: RequestOptions, callback: (data: unknown) => void, reportError = utils.console.error): void {
  httpJsonRequest({...options, method: 'GET'}, callback, reportError);
}

export function postJson(options: PostRequestOptions, callback: (data: unknown) => void, reportError = utils.console.error): void {
  httpJsonRequest({...options, method: 'POST'}, callback, reportError);
}

function httpJsonRequest(options: ExtendedRequestOptions, callback: (data: unknown) => void, reportError = utils.console.error): void {
  const xhr = new XMLHttpRequest();
  xhr.open(options.method || 'GET', options.url, true);
  if (options.headers) {
    options.headers.forEach((header) => {
      xhr.setRequestHeader(header.key, header.value);
    });
  }
  xhr.responseType = 'json';
  xhr.onload = () => {
    const status = xhr.status;
    if (status >= 200 && status < 300) {
      let response = xhr.response;
      if (typeof response === 'string') {
        try {
          response = JSON.parse(xhr.response);
        } catch {
          return reportError('Error: Content is malformed');
        }
      }
      callback(response);
    } else {
      reportError(`Error: ${xhr.statusText}`);
    }
  };
  if (options.body) {
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(options.body));
  } else {
    xhr.send();
  }
}
