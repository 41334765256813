import {REQUIRED_OBJECT, validateData} from '../lib/ValidateData';
import {tracker} from '../tracker';
import {utils} from '../utils';
import {ZaiusSDK} from '../Zaius';
import {getJson} from './httpRequest';
import {WebContent} from './WebContent';
import {WebPreview, WebPreviewData} from './WebPreview';
import {
  ZedWebDefinition, ZED_WEB_DEFINITION_SCHEMA, ZedWebCampaignDefinitions
} from './WebTriggerData';
import {WebTriggerEvaluator} from './WebTriggerEvaluator';

let instance: WebTrigger;

export class WebTrigger {
  private triggers: WebTriggerEvaluator[] = [];
  private firstPageview = true;

  private constructor() {
    // ensure we load the preview first, then normal triggers
    if (WebContent.isPreviewing()) {
      WebPreview.getPreview(WebContent.previewTrackerId()).then((preview: WebPreviewData) => {
        this.triggers.push(new WebTriggerEvaluator(
          WebContent.previewContentIdentifier() as string,
          preview.rules,
          preview.content,
          WebPreview.displayError,
          preview.static_context
        ));
      }).catch(() =>
        this.loadTriggers()
      );
    } else {
      this.loadTriggers();
    }
    (tracker.pageviewCallbacks as Array<() => void>).push(this.onPageview);
  }

  public static watch() {
    if (instance) {
      return;
    }
    instance = new WebTrigger();
  }

  private onPageview = () => {
    if (this.firstPageview) {
      this.firstPageview = false;
      return;
    }

    // refresh the triggers when the page changes (for single page apps)
    this.resetTriggers();
  };

  private loadTriggers() {
    const {config, trackerId} = tracker as ZaiusSDK;
    const url = `${config.webContent.source}${trackerId}/_rules.json`;
    getJson({
        url,
        headers: [{
          key: 'Cache-Control',
          value: 'no-cache'
        }]
      },
      this.handleRulesResponse,
      this.reportError
    );
  }

  private handleRulesResponse = (definitions: unknown) => {
    try {
      if (validateData<{[key: string]: unknown}>(definitions, {type: REQUIRED_OBJECT})) {
        this.initializeWebTriggers(definitions as ZedWebCampaignDefinitions);
      }
    } catch (error) {
      return this.reportError(`Failed to load content triggers: ${error.message}`);
    }
  };

  private initializeWebTriggers(definitions: ZedWebCampaignDefinitions) {
    for (const contentId in definitions) {
      if (definitions.hasOwnProperty(contentId)) {
        const content = definitions[contentId];
        this.initializeTrigger(contentId, content);
      }
    }
  }

  private initializeTrigger(contentId: string, triggerDefinition: ZedWebDefinition) {
    try {
      if (validateData(triggerDefinition, ZED_WEB_DEFINITION_SCHEMA)) {
        this.triggers.push(new WebTriggerEvaluator(contentId, triggerDefinition));
      }
    } catch (error) {
      this.reportError(`Failed to initialize trigger for ${contentId} with error ${error}`);
    }
  }

  private resetTriggers = () => {
    for (const trigger of this.triggers) {
      trigger.reset();
    }
  };

  private reportError(message: string) {
    utils.console.error(`[Web Content] ${message}`);
  }
}

if (SDK_TEST_MODE) {
  __internals.WebTrigger = {
    instance: () => instance,
    reset: () => {
      (instance as any) = undefined;
    }
  };
}
