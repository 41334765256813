import {tracker} from '../tracker';
import {EventAction, ZaiusEventData} from '../Zaius';
import {ConsentData, ConsentService} from './consent/ConsentService';
import {recordImpression} from './constraints/FrequencyConstraints';
import {WebContent} from './WebContent';
import {WebPreview} from './WebPreview';
import {Vendor, WebMode, ZedWebData} from './ZedWebData';
import {GridData, RenderContextData} from './WebContentInterface';
import {ProductRecommendationService} from './product-recommendations/ProductRecommendationService';
import {StaticContextService} from './static-context/StaticContextService';
import {StaticContext} from './static-context/StaticContext';

const SOURCE_ZAIUS = 'zaius';

export abstract class ContentManagerBase {
  protected webData!: ZedWebData;
  protected previewContext?: StaticContext;
  protected abstract readonly contentWindow: Window;
  protected frameLoaded!: () => void;
  protected frameLoadFailed!: (err?: Error) => void;
  protected frameLoad = new Promise<void>((resolve, reject) => {
    this.frameLoaded = resolve;
    this.frameLoadFailed = reject;
  });
  protected renderContextPromise?: Promise<RenderContextData>;
  protected gridDataPromise?: Promise<GridData[]>;

  public get webMode(): WebMode {
    return this.webData.config.content.type;
  }

  public destroy(): void { /* implement if needed */}
  public abstract reportError(message: string): void;
  public abstract showConfirmation(): void;
  public abstract contentReady(): void;
  public onSizeChange(_newSize: {width: number, height: number}): void { /* implement if needed */}
  public beforeRedirect(): void { /* implement if needed */}
  public afterSubmit(): void { /* implement if needed */}
  public afterOptOut(): void { /* implement if needed */}

  public fireConsentChange(consentData: ConsentData, enrich = true) {
    consentData.event_data = this.getEventData(enrich);
    return ConsentService.updateConsent(consentData);
  }

  public fireWebEvent(type: string, data: ZaiusEventData, enrich = true): Promise<void> {
    const enrichedData: ZaiusEventData = {
      ...data,
      ...this.getEventData(enrich)
    };

    if (WebContent.isPreviewing()) {
      WebPreview.logMessage(`Intercepted Event (type, action): (${type}, ${enrichedData.action})`);
      return Promise.resolve();
    }

    return tracker.event(type, enrichedData) as Promise<void>; // remove type coercion after converting tracker.js to ts
  }

  public fireEntity(type: string, data: ZaiusEventData): Promise<void> {
    if (WebContent.isPreviewing()) {
      WebPreview.logMessage(`Intercepted Entity (type): (${type})`);
      return Promise.resolve();
    }

    return tracker.entity(type, data) as Promise<void>;
  }

  public getGridData(): Promise<GridData[]> {
    if (this.webData.config.grids && this.webData.config.grids.length !== 0) {
      return this.gridDataPromise || (this.gridDataPromise = Promise.all(this.webData.config.grids!.map(({type, source, min_rows, min_columns, max_columns, max_rows, id, filter}) => {
        return ProductRecommendationService.fetch({
          type,
          source,
          limit: max_columns * max_rows || min_columns * min_rows,
          identifier: {
            vuid: tracker.vuid(),
            email: tracker.getEmail()
          },
          filter
        }).then((recommendations) => {
          if (recommendations.length < min_columns * min_rows) {
            this.reportError('Couldn\'t find enough product recommendations for the grid');
            throw new Error('Insufficient recommendations returned');
          }
          return {
            grid_id: id,
            data: recommendations
          };
        });
      })));
    }
    return Promise.resolve([]);
  }

  public getRenderContextData(): Promise<RenderContextData> {
    return this.renderContextPromise || (this.renderContextPromise = Promise.all([this.getGridData(), this.getStaticContextData()]).then((context) => {
      const [gridData, staticContext] = context;
      return {
        gridData,
        staticContext
      };
    }));
  }

  public getStaticContextData(): Promise<StaticContext> {
    // If there is a static context use it otherwise fetch
    if (this.previewContext) {
      return Promise.resolve(this.previewContext);
    } else {
      return StaticContextService.fetch();
    }
  }

  public recordImpression() {
    const {config: {content}} = this.webData;
    this.fireWebEvent(this.webMode, {action: EventAction.Impression});
    recordImpression(content.eventEnrichment.content || content.id.toString());
  }

  private getEventData(enrich: boolean) {
    const eventData: ZaiusEventData = {
      ...this.getMeta()
    };

    if (enrich) {
      Object.assign(eventData, this.getEnrichmentData());
    }

    return eventData;
  }

  private getMeta() {
    const {identifier_key} = this.webData.config.content.eventEnrichment;
    const vendor = [WebMode.WebEmbed, WebMode.WebModal, WebMode.PreferenceCenter].includes(this.webMode) ? Vendor.Zaius : '';

    return {
      identifier_key,
      vendor
    };
  }

  private getEnrichmentData() {
    const {eventEnrichment} = this.webData.config.content;

    return {
      ...eventEnrichment,
      medium: this.webMode,
      source: SOURCE_ZAIUS
    };
  }
}
