import {utils} from '../../utils';
import {ComparatorPredicateEvaluator} from './ComparatorPredicateEvaluator';
import {AndPredicateEvaluator} from './AndPredicateEvaluator';
import {OrPredicateEvaluator} from './OrPredicateEvaluator';
import {PredicateEvaluator, WebContentPredicate, WebContentPredicateOperators} from './PredicateCommon';

export function baseUrl(location: Location | HTMLAnchorElement) {
  const {protocol, hostname, port, pathname} = location;

  // strip off trailing `/`, `/index.html`, etc
  const path = pathname.replace(/\/(?:index\.\w{1,5})?$/, '');

  return `${protocol}//${hostname}${port ? ':' + port : ''}${path}`;
}

export function unmarshalPredicate(predicate: WebContentPredicate): PredicateEvaluator {
  switch (predicate.operator) {
    case WebContentPredicateOperators.Contains:
    case WebContentPredicateOperators.DoesNotContain:
    case WebContentPredicateOperators.Equals:
    case WebContentPredicateOperators.DoesNotEqual:
      return new ComparatorPredicateEvaluator(predicate.operator, predicate.field, predicate.value);
    case WebContentPredicateOperators.And:
        return new AndPredicateEvaluator(predicate.operator, predicate.predicates);
    case WebContentPredicateOperators.Or:
        return new OrPredicateEvaluator(predicate.operator, predicate.predicates);
  }
}

export function pageMatchesPredicate(predicate: WebContentPredicate) {
  const evaluator = unmarshalPredicate(predicate);
  const pageUrl = baseUrl(window.location).replace(/^(https?:\/\/)/, '');
  const fullPageUrl = `${window.location}`.replace(/^(https?:\/\/)/, '');
  const parameters = {
    ...Object.entries(utils.params(true)).reduce((acc, [key, value]) => {
      acc[key.toLowerCase()] = value;
      return acc;
    }, {} as {[key: string]: string}),
    page_url: pageUrl,
    full_page_url: fullPageUrl
  };
  return evaluator.evaluate(parameters);
}
