import tracker from '../tracker';
import {utils} from '../utils';
import {postJson} from './httpRequest';
import {RecentEvent} from './RecentEventsCollector';

interface RealtimeSegmentsQueryProps {
  realtimeSegments: string[];
  recentEvents: RecentEvent[];
  onQuerySuccess: (matchedRealtimeSegments: string[]) => void;
}

interface GraphqlQueryProps {
  query: string;
  variables: {
    vuid: string;
    realtimeSegments: string[];
    recentEvents: RecentEvent[];
  };
  operationName: string;
}

export const RealtimeSegmentsQuery = {
  /* public */
  query({realtimeSegments, recentEvents, onQuerySuccess}: RealtimeSegmentsQueryProps) {
    const handleSuccess = (response: any) => {
      onQuerySuccess(this.matchedRealtimeSegments(response));
      this.logWarningsIfAny(response);
    };
    const handleFailure = () => onQuerySuccess([]);

    postJson(
      {
        url: `${tracker.publicApiUrl}/graphql`,
        headers: [{
          key: 'x-api-key',
          value: tracker.trackerId as any
        }],
        body: this.buildBody(realtimeSegments, recentEvents)
      },
      handleSuccess,
      handleFailure
    );
  },

  matchedRealtimeSegments(response: any): string[] {
    return (response?.data?.customer?.audiences?.edges || [])
      .map((edge: any) => edge?.node?.name)
      .filter((name: string | undefined) => name);
  },

  buildBody(realtimeSegments: string[], recentEvents: RecentEvent[]): GraphqlQueryProps {
    return {
      query: `query InclusionQuery($vuid: String, $realtimeSegments: [String], $recentEvents: [json]) {
        customer(vuid: $vuid) {
          audiences(subset: $realtimeSegments, recent_events: $recentEvents) {
            edges {
              node {
                name
              }
            }
          }
        }
      }`,
      variables: {
        vuid: this.getVuid(),
        realtimeSegments,
        recentEvents
      },
      operationName: 'InclusionQuery'
    };
  },

  getVuid(): string {
    return (tracker.VUID as unknown as string).split('-').join('');
  },

  logWarningsIfAny(response: any) {
    if (response?.extensions?.warnings?.length || 0 > 0) {
      utils.console.warn('Graphql InclusionQuery finished successfully but with warnings, please check related request!');
    }
  }
};
