import pushOptIn from './optin';
import {resolveWebPushReady} from './initialize';
import tracker from '../tracker';

let webServiceURL;
let pushConfig;

const safariPusher = {
  subscribe(data) {
    return new Promise(function (resolve) {
      const event = {
        'action': 'add_push_token',
        'app_id': pushConfig['websiteID'],
        'zaius_alias_safari_token': data['deviceToken']
      };
      event[pushConfig['websiteID'] + '_safari_push_tokens'] = data['deviceToken'];
      tracker.updateWebPushToken('safari', pushConfig['websiteID'], data['deviceToken']);
      zaius.event('push', event);
      resolve();
    });
  },

  initialize(webPushConfig, data) {
    pushConfig = webPushConfig;
    if (data['permission'] === 'default') {
      webServiceURL = tracker.config['trackerUrl'] + '/safari';
      if (pushConfig['webServiceURL']) {
        webServiceURL = pushConfig['webServiceURL'];
      }
      pushOptIn.showOptIn(webPush['askToSubscribe'], pushConfig);
    } else if (data['permission'] === 'denied') {
      tracker.updateWebPushToken('safari', pushConfig['websiteID'], null);
    } else if (data['permission'] === 'granted') {
      tracker.updateWebPushToken('safari', pushConfig['websiteID'], data['deviceToken']);
    }
    resolveWebPushReady(data['permission']); // see initialize.js
  },

  askToSubscribe() {
    return new Promise(function (resolve) {
      window.safari.pushNotification.requestPermission(
        webServiceURL,
        pushConfig['websitePushID'],
        {"tracker_id": tracker.trackerId, "website_id": pushConfig['websiteID']},
        function (data) {
          if (data['permission'] === 'denied') {
            pushOptIn.hideOptIn();
            resolve(data['permission']);
          } else if (data['permission'] === 'granted') {
            safariPusher.subscribe(data).then(pushOptIn.hideOptIn).then(function () {
              resolve('subscribed');
            });
          } else {
            resolve(data['permission']);
          }
        }
      );
    });
  }
};

export default safariPusher;
