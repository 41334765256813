import {utils} from '../utils';

let element;
let card;
let closeButton;
let subscribeHandler;
let minimizeTimer;
let minimized = false;
const config = {
  'enabled': true,
  'position': 'left',
  'fontFamily': '"Helvetica Neue", Arial, sans-serif',
  'fontWeight': '200',
  'fontSize': 14,
  'background': 'rgba(0,0,0,.85)',
  'titleText': 'Looking for exclusive deals?',
  'titleColor': '#e1e1e1',
  'bodyText': 'Subscribe to browser notifications',
  'bodyColor': '#e1e1e1',
  'buttonText': 'SUBSCRIBE',
  'buttonColor': '#e1e1e1',
  'buttonBorder': '1px solid #e1e1e1',
  'buttonBackground': '',
  'buttonHoverBackground': '',
  'buttonHoverColor': '#ffffff',
  'buttonHoverBorder': '1px solid #ffffff',
  'cancelColor': '#d1d1d1',
  'cancelText': 'Maybe Later',
  'cancelDuration': 20 * 60,
  'showDelay': 3,
  'showFrequency': 5 * 60,
  'autoHideDelay': 10
};

const pushOptIn = {
  hideOptIn() {
    if (element && card) {
      card.style.opacity = '0';
      setTimeout(function () {
        card.style.opacity = '1';
        element.remove();
      }, 1000);
    }
  },

  showOptIn(handler, pushConfig) {
    try {
      assign(config, pushConfig['subscribeUI']);
    } catch (e) {
      utils.console.error(e);
    }

    if (!config['enabled'] || isDismissed()) {
      return;
    }

    subscribeHandler = handler;
    if (element) {
      if (!element.parentNode) {
        document.body.appendChild(element);
      }
      return;
    }

    try {
      element = createOptIn();
      showAfterDelay();
    } catch (e) {
      // console.log(e)
    }
  }
};

function isDismissed() {
  try {
    let until = utils.getCookie('zaiusPushSubscribeDismissedUntil');
    if (until && (until = parseInt(until, 10))) {
      return new Date().getTime() < until;
    }
  } catch (e) {
    console.error(e);
  }
  return false
}

function dismiss() {
  pushOptIn.hideOptIn();
  utils.setCookie(
    'zaiusPushSubscribeDismissedUntil',
    (new Date().getTime() + config['cancelDuration'] * 1000).toString(),
    30
  );
}

function shouldPop() {
  try {
    let last = utils.getCookie('zaiusPushSubscribePopped');
    if (last && (last = parseInt(last, 10))) {
      return new Date().getTime() > last + parseInt(config['showFrequency'], 10) * 1000;
    }
  } catch (e) {
    console.error(e);
  }
  return true
}

function pop() {
  minimized = false;
  clearTimeout(minimizeTimer);
  const offset = '-' + (card.offsetHeight - parseInt(titleHeight(), 10)) + 'px';
  card.style.cursor = 'default';
  card.style.transform = 'translateY(' + offset + ') translateZ(0)';
  closeButton.querySelector('svg').style.transform = 'rotateX(0deg)';
  utils.setCookie('zaiusPushSubscribePopped', new Date().getTime().toString(), 30);
}

function showAfterDelay() {
  card.style.opacity = '0';
  card.style.pointerEvents = 'none';
  document.body.appendChild(element);
  setTimeout(function () {
    card.style.pointerEvents = 'auto';
    card.style.opacity = '1';
    if (shouldPop()) {
      pop();
      minimizeTimer = setTimeout(minimize, parseInt(config['autoHideDelay'], 10) * 1000);
    }
  }, parseInt(config['showDelay'], 10) * 1000);
}

function createOptIn() {
  const container = document.createElement('div');
  assign(container.style, {
    position: 'fixed',
    'z-index': '2000000000',
    bottom: titleHeight(),
    right: config['position'] === 'right' ? '20px' : undefined,
    left: config['position'] !== 'right' ? '20px' : undefined
  });

  card = document.createElement('div');
  assign(card.style, {
    position: 'absolute',
    right: config['position'] === 'right' ? 0 : undefined,
    left: config['position'] !== 'right' ? 0 : undefined,
    top: '0px',
    width: '350px',
    background: config['background'],
    'border-radius': '2px',
    'text-align': 'center',
    'transform': 'translateY(0) translateZ(0)',
    'transition': 'transform 250ms ease-out,opacity 500ms'
  });

  card.appendChild(createClose());
  card.appendChild(createTitle());
  card.appendChild(createBody());
  card.appendChild(createButton());
  card.appendChild(createLaterLink());

  card.addEventListener('click', pop);
  card.addEventListener('mouseout', onMouseOut);

  container.appendChild(card);
  return container;
}

function createClose() {
  closeButton = document.createElement('div');
  assign(closeButton.style, assign(baseStyles(), {
    position: 'absolute',
    right: '0',
    top: '0',
    padding: '10px 15px',
    color: config['titleColor'],
    cursor: 'pointer'
  }));
  closeButton.innerHTML = `
    <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="width: 16px; height: 16px; transition: all 500ms ease-out">
      <path fill="currentColor" d="M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z" />
    </svg>`;
  closeButton.addEventListener('click', togglePanel);
  closeButton.addEventListener('touchstart', togglePanel);
  return closeButton;
}

function togglePanel(e) {
  e.stopPropagation();
  e.preventDefault();
  minimized ? pop() : minimize();
}

function createTitle() {
  const div = document.createElement('div');
  assign(div.style, assign(baseStyles(), {
    padding: '10px 20px 5px 20px',
    color: config['titleColor']
  }));
  div.textContent = config['titleText'];
  return div;
}

function createBody() {
  const div = document.createElement('div');
  assign(div.style, assign(baseStyles(), {
    padding: '0 5px 5px 5px',
    color: config['bodyColor']
  }));
  div.textContent = config['bodyText'];
  return div;
}

function createButton() {
  const wrapper = document.createElement('div');
  wrapper.id = 'zaius_subscribe_ui';
  wrapper.style.padding = '10px';
  const div = document.createElement('div');
  assign(div.style, assign(baseStyles(), {
    display: 'inline-block',
    padding: '8px 35px',
    background: config['buttonBackground'],
    color: config['buttonColor'],
    border: config['buttonBorder'],
    cursor: 'pointer',
    transition: 'all 100ms'
  }));
  div.textContent = config['buttonText'];
  wrapper.appendChild(div);
  wrapper.addEventListener('click', subscribeHandler);
  div.addEventListener('mouseover', function () {
    div.style.background = config['buttonHoverBackground'];
    div.style.color = config['buttonHoverColor'];
    div.style.border = config['buttonHoverBorder'];
  });
  div.addEventListener('mouseout', function () {
    div.style.background = config['buttonBackground'];
    div.style.color = config['buttonColor'];
    div.style.border = config['buttonBorder'];
  });
  return wrapper;
}

function createLaterLink() {
  const wrapper = document.createElement('div');
  const div = document.createElement('div');
  assign(div.style, assign(baseStyles(), {
    display: 'inline-block',
    padding: '10px',
    color: config['cancelColor'],
    'font-size': parseInt(config['fontSize'], 10) * 0.8 + 'px',
    cursor: 'pointer'
  }));
  div.textContent = config['cancelText'];
  div.addEventListener('click', dismiss);
  wrapper.appendChild(div);
  return div;
}

function titleHeight() {
  return (parseInt(config['fontSize'], 10) * 1.5 + 15) + 'px';
}

function onMouseOut() {
  // close button instead?
  clearTimeout(minimizeTimer);
  minimizeTimer = setTimeout(minimize, parseInt(config['autoHideDelay'], 10) * 1000)
}

function minimize() {
  minimized = true;
  card.style.cursor = 'pointer';
  card.style.transform = 'translateY(0) translateZ(0)';
  closeButton.querySelector('svg').style.transform = 'rotateX(180deg)';
  clearTimeout(minimizeTimer);
}

function baseStyles() {
  return {
    'font-family': config['fontFamily'],
    'font-weight': config['fontWeight'],
    'font-size': parseInt(config['fontSize'], 10) + 'px',
    '-webkit-font-smoothing': 'initial',
    'letter-spacing': '1px',
    'box-sizing': 'content-box'
  }
}

function assign(dest, src) {
  if (typeof src === 'object' && typeof dest === 'object') {
    for (const key in src) {
      if (src.hasOwnProperty(key)) {
        dest[key] = src[key];
      }
    }
  }
  return dest;
}

export default pushOptIn;
