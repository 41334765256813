import {validateData} from '../lib/ValidateData';
import {tracker} from '../tracker';
import {ZaiusSDK} from '../Zaius';
import {getJson} from './httpRequest';
import {ZED_WEB_DATA_SCHEMA, ZedWebData} from './ZedWebData';

export default class ContentLoader {
  private static content: {[key: string]: Promise<ZedWebData>} = {};

  public static load(contentId: string): Promise<ZedWebData> {
    let contentPromise = this.content[contentId];

    if (!contentPromise) {
      const {config, trackerId} = tracker as ZaiusSDK;
      const url = `${config.webContent.source}${trackerId}/${contentId}.json`;

      contentPromise = new Promise<ZedWebData>((resolve, reject) => {
        getJson({
            url,
            headers: [{
              key: 'Cache-Control',
              value: 'no-cache'
            }]
          },
          (response: unknown) => {
          try {
            validateData(response, ZED_WEB_DATA_SCHEMA) && resolve(response);
          } catch (error) {
            reject(error);
          }
        });
      });

      this.content[contentId] = contentPromise;
    }

    return contentPromise;
  }
}
