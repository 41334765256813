export type TriggerHandler = () => void;

export abstract class Trigger<T> {
  private triggers: T[] = [];
  private handlers: TriggerHandler[] = [];
  protected static instance: any;

  public static getInstance() {
    /* Must be implemented by subclass */
    throw new Error();
  }

  protected constructor() {}

  public addTrigger(trigger: T, handler: TriggerHandler) {
    this.triggers.push(trigger);
    this.handlers.push(handler);
  }

  public removeTrigger(trigger: T) {
    const index = this.triggers.indexOf(trigger);
    if (index !== -1) {
      this.triggers.splice(index, 1);
      this.handlers.splice(index, 1);
    }
  }

  protected forEach(callback: (trigger: T, handler: TriggerHandler) => void) {
    // iterate in reverse so that a trigger can remove itself during the callback
    for (let i = this.triggers.length - 1; i >= 0; i--) {
      callback(this.triggers[i], this.handlers[i]);
    }
  }

  public reset() {
    // implement if needed
  }
}
