import {ZedWebData} from '../web/ZedWebData';
import {MobileUtilities} from './MobileUtilities';

export namespace WebContentUtilities {
  export function updateSize(iframe: HTMLIFrameElement, zedDefinedWidth: number, height: number, data: ZedWebData) {
    const {properties: {minHeight, width: userDefinedWidth}} = data.config;
    if (iframe.contentDocument!.body) {
      updateWidth(iframe, zedDefinedWidth, userDefinedWidth);
      updateHeight(iframe, height, minHeight);
    }
  }

  export function updateHeight(iframe: HTMLIFrameElement, height: number, minHeight: number) {
    if (height) {
      iframe.style.height = Math.max(height, minHeight) + 'px';
    }
  }

  /***
   * Follow same suit as modal, except we'll set width to the viewport width
   * when the user sets width in zed to 'auto'
   *
   * If the width is auto and the user is on mobile, we can detect a mobile device.
   * However, if the user is not on mobile (desktop/laptop), then we have no way of
   * knowing when to enable responsiveness as there is no default width like a modal.
   * This is because we set the iframe to be viewport width, and all window/iframe bound calculations
   * will be equivalent.
   *
   * @param iframe
   * @param width
   */
  export function updateWidth(iframe: HTMLIFrameElement, width: number, userDefinedWidth?: number) {
    const parentWidth = calculateParentWidth(iframe);
    let zedWebParentWidth = userDefinedWidth ? userDefinedWidth : parentWidth;
    // If we're on mobile then the width cannot exceed the available width, so use the parent width if it's less than the user defined width
    if (MobileUtilities.isMobile() && zedWebParentWidth > parentWidth) {
      zedWebParentWidth = parentWidth;
    }
    iframe.style.width = (width ? Math.min(zedWebParentWidth, width, parentWidth) : zedWebParentWidth) + 'px';
    if (MobileUtilities.isMobile() || (userDefinedWidth && userDefinedWidth >= viewportWidth())) {
      iframe.contentDocument!.body.classList.add('mobile');
    } else {
      iframe.style.margin = '0 auto';
      iframe.style.display = 'block'; // ensures web content is centered
      iframe.contentDocument!.body.classList.remove('mobile');
    }
  }

  export function calculateParentWidth(iframe: HTMLIFrameElement) {
    const parentElement = iframe.parentElement;
    if (!parentElement) {
      return 0;
    }
    const iframeWidth = iframe.style.width;
    // This will cause a reflow, so I wish there was a better alternative here. But I don't think there's
    // another way of knowing what the parent's width without the iframe asserting it's own width.
    iframe.style.width = 'auto';

    // The parent could have padding, which would be included in the width from `getBoundingClientRect`,
    // but will actually be an additional width restriction on the embed.
    const {paddingLeft, paddingRight} = getComputedStyle(parentElement);
    const parentWidth = parentElement.getBoundingClientRect().width - (parseFloat(paddingLeft) || 0) - (parseFloat(paddingRight) || 0);

    iframe.style.width = iframeWidth;
    return parentWidth;
  }

  export function viewportWidth() {
    return Math.min(window.innerWidth, screen.width);
  }

  export function viewportHeight() {
    return window.innerHeight;
  }

  export function preloadImages(html: string) {
    const imgRegex = /<img\s[^>]*\bsrc="(.*?)"/gi;
    let matches = imgRegex.exec(html);
    while (matches) {
      new Image().src = matches[1];
      matches = imgRegex.exec(html);
    }
  }
}
