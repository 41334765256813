import tracker from '../../tracker';
import {getJson} from '../httpRequest';
import {validateData} from '../../lib/ValidateData';
import {STATIC_CONTEXT_SCHEMA, StaticContext} from './StaticContext';
import {ZaiusSDK} from '../../Zaius';

export class StaticContextService {
  private static context?: Promise<StaticContext>;
  public static fetch(): Promise<StaticContext> {
    return this.context || (this.context = new Promise(((resolve, reject) => {
      const {config, trackerId} = tracker as ZaiusSDK;
      const url = `${config.webContent.source}${trackerId}/_static_context.json`;
      getJson({
        url,
        headers: [{
          key: 'Cache-Control',
          value: 'no-cache'
        }]
      }, (staticContext) => {
        try {
          if (validateData(staticContext, STATIC_CONTEXT_SCHEMA)) {
            resolve(staticContext);
          }
        } catch (error) {
          reject(error);
        }
      });
    })));
  }
}
