import {RealtimeSegmentsQuery} from './RealtimeSegmentsQuery';
import {RecentEventsCollector} from './RecentEventsCollector';

export interface RealtimeSegmentsEvaluatorProps {
  realtimeSegments: string[];
  onSuccess: () => void;
}

export const RealtimeSegmentsEvaluator = {
  evaluate({realtimeSegments, onSuccess}: RealtimeSegmentsEvaluatorProps) {
    // No realtime segments means 'All Customers'
    if (realtimeSegments.length === 0) {
      onSuccess();
      return;
    }

    const querySuccessHandler = (matchedRts: string[]) => {
      if (matchedRts.length > 0) {
        onSuccess();
      }
    };

    RealtimeSegmentsQuery.query({
      realtimeSegments,
      recentEvents: RecentEventsCollector.getAll(),
      onQuerySuccess: querySuccessHandler
    });
  }
};
