import {debounced} from '../../lib/Debounce';
import {ScrollTriggerConfig} from '../WebTriggerData';
import {Trigger, TriggerHandler} from './Trigger';

// minimum available scrollable space required before any trigger can be activated
const MIN_SCROLLABLE_PIXELS = 200;

export class ScrollTrigger extends Trigger<ScrollTriggerConfig> {
  public static getInstance(): ScrollTrigger {
    return this.instance || (this.instance = new ScrollTrigger());
  }

  protected constructor() {
    super();
    window.addEventListener('scroll', debounced(250, 1000)(() => this.forEach(this.evaluate)), {passive: true});
  }

  private evaluate = (definition: ScrollTriggerConfig, handler: TriggerHandler): void => {
    if (definition.enabled) {
      if (this.scrollPercent() >= definition.percent) {
        handler();
      }
    }
  };

  private scrollPercent() {
    const {documentElement, body} = document;

    const scrollHeight = documentElement.scrollHeight || body.scrollHeight;
    const scrollableArea = scrollHeight - documentElement.clientHeight;
    if (scrollableArea > MIN_SCROLLABLE_PIXELS) {
      return (documentElement.scrollTop || body.scrollTop) / scrollableArea * 100;
    }
    return 0;
  }
}
