import {dimensions} from './dimensions';
import {RecentlyViewedProducts} from './lib/RecentlyViewedProducts';
import {webPush} from './push/initialize';
import {search} from './search';
import {utils} from './utils';
import {ConsentService} from './web/consent/ConsentService';
import {WebContent} from './web/WebContent';
import {RealtimeSegmentsProxy} from './web/RealtimeSegmentsProxy';

// Configuration Defaults
// ----------------------
const DEFAULT_CONFIG = {

  // The data collection url.
  trackerUrl: '',

  // The public Zaius API url.
  publicApiUrl: '',

  // Timeout for the unique tracker cookie in days.
  visitorCookieExpiryDays: 365 * 2,

  // Timeout for the referrer tracker cookie in days.
  referrerCookieExpiryDays: 60,

  // Cookie domain.  For tracking across several subdomains set this at
  // the highest domain possible by omitting the www and . (dot) prefix
  // of the domain.  Defaults to the current full subdomain path.
  cookieDomain: '',

  // Hash of third party vendors for ID Sync keyed by vendor name
  idSyncs: {},

  // Include the query string part of the url.  By default this is turned `off`.
  // When `on` the path for the page will include the value of location.search.
  includeQueryString: false,

  // Include the hash part of the url.  By default this is turned `off`.  When
  // `on` the path for the page will include the value of location.hash.
  includeHash: false,

  // Convert the query string and hash parameters to params. By default this is turned `off`.
  convertParameters: false,

  // Controls behavior of zm64_id. false -> send zm64_id on every event; true -> send 1 special type event with zm64_id
  emitIdentityEvent: false,

  // Controls is real time segments are enabled or not
  realtimeSegments: {
    enabled: true,
    maxRecentEvents: 100, // A 100 records take ~125KB
    recentEventsTTL: 5 * 60 * 1000, // 5 mins, typically the ingest lag is under 5 mins
  },

  // Hash of app_id to push configuration
  webPushConfigs: {},

  webContent: {
    source: WEBCONTENT_SOURCE_BASE_URL,
    enabled: false
  }
};

// The tracker singleton will be exposed in the global namespace as `zaius`.
export const tracker = {

  // Current Zaius JS configuration.
  /** @type {Object} */
  config: null,

  // The data collection url.
  trackerUrl: null,

  // The public Zaius API url
  publicApiUrl: null,

  // The client's tracking identifier.  This string contains two
  // pieces of information: the code to uniquely identify the client
  // and a code to uniquely identify the site for which the tracking
  // params are scoped.
  trackerId: null,

  // Used to track if initialized multiple times with different tracker ids
  isMultipleTrackerIds: false,

  // Visitor UUID
  VUID: undefined,

  // The client's base64-encoded email address
  zm64_id: null,

  // Used to determine if `zaius` has been initialized.
  initialized: false,

  // First pageview call.  Helps to manage the new user flag while exposing
  // the VUID after initialization
  firstPageView: true,

  // List of callbacks to be called when a pageview event fires
  pageviewCallbacks: [],

  // Standard mixin for the data source fields
  dataSourceFields: {
    data_source_type: 'sdk',
    data_source: 'JavaScript',
    data_source_instance: window.location.hostname,
    data_source_version: ZAIUS_JS_VERSION
  },

  // Initialize
  // ----------

  // Call **initialize** to setup Zaius JS before tracking events
  //
  // * `trackerId` the Zaius supplied tracking key.
  //
  // Usage:
  //
  //     zaius.initialize(my_tracking_key);
  //
  initialize(trackerId, opts) {
    if (this.initialized) {
      utils.console.warn('Multiple initializations have occurred. `zaius.initialize` should be called only once.');
    }

    if (this.trackerId && this.trackerId !== trackerId) {
      this.isMultipleTrackerIds = true;
      utils.console.error('`zaius.initialize` called again with a new tracker ID: ' + trackerId + ' has overwritten ' + this.trackerId);
    }

    this.initialized = false;
    this.trackerId = null;
    this.config = utils.deepClone(DEFAULT_CONFIG);
    this.VUID = window['zaius']['VUID'] = undefined;
    this.customerId = undefined;
    dimensions.lastVisit = 0;

    if (!utils.isString(trackerId) || !trackerId) {
      throw new Error('Your Zaius supplied tracker ID is required');
    }

    if (opts !== null && typeof opts !== 'undefined') {
      if (typeof opts !== 'object') {
        utils.console.error('Ignoring initialize config object, type is ' + (typeof opts));
      } else {
        for (const key in opts) {
          if (opts.hasOwnProperty(key) && !DEFAULT_CONFIG.hasOwnProperty(key)) {
            utils.console.error('Ignoring unrecognized config field during initialize: ' + key);
          }
        }
        this.config = utils.mixin(this.config, opts);
      }
    }

    this.migrate(); // migrate any data changes
    this.trackerId = trackerId;
    this.initialized = true;
    this.newUser = this.isNewUser();
    this.VUID = window['zaius']['VUID'] = this.vuid();
    this.zm64_id = this.getZm64Id();
    this.customerId = this.getOrRefreshCustomerId();
    this.trackerUrl = this.trackerUrl || this.config['trackerUrl'];
    this.publicApiUrl = this.publicApiUrl || this.config['publicApiUrl'];

    if (this.config.hasOwnProperty('idSyncs')) {
      this.synchronizeVuid(this.config['idSyncs']);
    }

    if (this.config.hasOwnProperty('webPushConfigs')) {
      this.autoInitWebPush(this.config['webPushConfigs']);
    }

    if (this.config['emitIdentityEvent'] && this.zm64_id) {
      // Ensure we emit Identity event if click-through identifier (zm64_id) is present
      // Initialize method is the only place guaranteed to be called
      // we want to send zm64_id once and do not include it in any subsequent traffic
      this.fireIdentityEvent();
    }

    if (this.config.webContent.enabled || WebContent.isPreviewing()) {
      WebContent.initialize();
    }

    RealtimeSegmentsProxy.initializeStorage();

    RecentlyViewedProducts.removeStaleProducts();
  },

  // Customer
  // --------

  // Call **customer** to send a customer to Zaius.  If a customer id is supplied in
  // the identifiers, it will be associated with any future events in the same web session.
  // See **identify**.
  //
  //
  // Usage:
  //
  //     zaius.customer({
  //       customer_id: "123",
  //       email: "johnny.zaius@zaius.com",
  //     },{
  //       first_name: "Johnny",
  //       last_name: "Zaius",
  //       gender: "M"
  //     });
  //
  customer(identifiers, attributes) {
    if (identifiers == null) {
      identifiers = {};
    }

    if (identifiers.customer_id != null) {
      this.setOrUpdateCustomerId(identifiers.customer_id);
    }

    if (attributes == null) {
      attributes = {};
    }

    const tracking = this.tracking();
    return this.entity('customer', utils.mixin(tracking, identifiers, attributes));
  },

  // Entity
  // --------

  // Call **entity** to send object data to Zaius
  //
  //
  // Usage:
  //
  //     zaius.entity('product', {
  //       product_id: '123',
  //       first_name: 'Bob'
  //     });
  //
  entity(entityType, entity) {
    if (entity === null || entity === undefined) {
      entity = {};
    }
    // API allows for singular name or plural dimension name here, e.g., customer_dimension or customers_dimension.
    // Server does schema-based coercion to plural dimension name.  Allows for singular to end in 's', or plural
    // not to end in 's'.
    const eventType = entityType + '_dimension';
    const requiredFields = this.requiredFields(eventType);
    return this.fire(utils.deepClone(utils.mixin(requiredFields, this.dataSourceFields, entity)));
  },

  // Event
  // -----

  // Call **event** to send an event to Zaius
  //
  //
  // Usage:
  //
  //     zaius.event('pageview', {
  //       customer_id : '123',
  //       title   : 'homepage',
  //       page    : '/index.html',
  //       custom1 : 'some value',
  //       custom2 : 'another value'
  //     });
  //
  event(eventType, event) {
    if (this.isMultipleTrackerIds) {
      utils.console.error('Multiple tracker IDs error. Please check usage of zaius.initialize')
    }

    if (event === null || event === undefined) {
      event = {};
    }
    const requiredFields = this.requiredFields(eventType);
    const dims = dimensions.collect();
    const tracking = this.tracking();
    return this.fire(utils.deepClone(utils.mixin(dims, tracking, requiredFields, this.dataSourceFields, event)));
  },

  // Subscribe
  // ---------

  // Call **subscribe** to subscribe a customer to a list
  //
  //
  // Usage:
  //
  //     zaius.subscribe({
  //       list_id    : 'newsletter',
  //       email      : 'someone@foo.com',
  //       first_name : 'John',
  //       last_name  : 'Doe'
  //     });
  //
  subscribe(subscriptionObject, onSuccess, onError) {
    this.handleListMembership(subscriptionObject, "subscribe", onSuccess, onError);
  },

  // Unsubscribe
  // ---------

  // Call **unsubscribe** to unsubscribe a customer from a list
  //
  //
  // Usage:
  //
  //     zaius.unsubscribe({
  //       list_id    : 'newsletter',
  //       email      : 'someone@foo.com',
  //       first_name : 'John',
  //       last_name  : 'Doe'
  //     });
  //
  unsubscribe(subscriptionObject, onSuccess, onError) {
    this.handleListMembership(subscriptionObject, "unsubscribe", onSuccess, onError);
  },

  // Consent
  // ---------

  // Call **consent** to manage identifier level consent
  //
  //
  // Usage:
  //
  //     zaius.consent({
  //       consent: true,
  //       identifier_field_name: 'email',
  //       identifier_value: 'tyler@zaius.com',
  //       update_reason: '',
  //       update_ts: 123456789,
  //       event_data: {}
  //     });
  //
  // * `consent` optional true/false, defaults to true
  // * `identifier_field_name` optional, defaults to email
  // * `identifier_value` required string
  // * `update_reason` optional string
  // * `update_ts` optional ts
  // * `event_data` optional object holding additional event fields
  //
  consent(consentObject, onSuccess, onError) {
    ConsentService.updateConsent(consentObject, onSuccess, onError);
  },

  // handleListMembership
  // ---------

  // Call **handleListMembership** to subscribe or unsubscribe a customer from a list
  // Also supports multiple-list actions by passing list_id as an array of list_ids
  //
  //
  // Usage:
  //
  //     zaius.handleListMembership({
  //       list_id:   : 'newsletter',
  //       email      : 'someone@foo.com',
  //       first_name : 'John',
  //       last_name  : 'Doe'
  //     }, 'subscribe');
  //
  handleListMembership(subscriptionObject, list_action, onSuccess, onError) {
    const logSuccess = function () {
      if (onSuccess && utils.isFunction(onSuccess)) {
        onSuccess();
      }
    };
    const logError = function (msg) {
      if (onError && utils.isFunction(onError)) {
        onError(msg);
      }
    };
    let eventObject = utils.deepClone(subscriptionObject);
    let lists, i;
    if (!utils.isString(list_action) || (list_action !== 'subscribe' && list_action !== 'unsubscribe')) {
      logError('handleListMembership needs list_action of "subscribe" or "unsubscribe"');
      return;
    }
    if (!utils.isObject(eventObject)) {
      logError('subscriptionObject must have required fields \'list_id\' and \'email\'');
      return;
    }
    if (!utils.isValidEmail(eventObject['email'])) {
      logError('invalid email ' + eventObject['email']);
      return;
    }
    if (utils.isString(eventObject['list_id'])) {
      if (eventObject['list_id'].length === 0) {
        logError('invalid list_id string: must have nonzero length');
        return;
      } else {
        lists = [eventObject['list_id']];
      }
    } else if (!Array.isArray(eventObject['list_id']) || eventObject['list_id'].length === 0) {
      logError('list_id must be a non-empty string or array of non-empty strings');
      return;
    } else {
      for (i = 0; i < eventObject['list_id'].length; i++) {
        if (!utils.isString(eventObject['list_id'][i]) || eventObject['list_id'][i].length === 0) {
          logError('list_id array may not contain empty strings');
          return;
        }
      }
      lists = eventObject['list_id'];
    }
    delete eventObject['list_id'];
    eventObject = utils.compact(eventObject);
    if (utils.numProperties(eventObject) > 1) {
      this.entity('customer', eventObject);
    }

    eventObject['active_event'] = true;
    const promises = [];
    for (i = 0; i < lists.length; i++) {
      promises.push(this.event('list', utils.mixin(eventObject, {
        action: list_action,
        list_id: lists[i]
      })));
    }
    Promise.all(promises)
      .then(() => logSuccess())
      .catch(() => logError('Failed to send subscribe/unsubscribe events to Zaius. Please disable any ad or traffic blocker plugins.'));
  },

  // Identify
  // --------

  // Call **identify** with a customer_id to associate any future events in the same web session to the given customer.
  //
  //
  // Usage:
  //
  //     zaius.identify()
  //
  identify(customerId) {
    if (!utils.isString(customerId) || utils.trimString(customerId) === '') {
      return;
    }
    tracker.setOrUpdateCustomerId(customerId);
  },

  // Anonymize
  // ---------

  // Call **anonymize** to force this customer to be anonymous. Removes any previously set customer_id and
  // generates a new vuid for the customer. Campaign source information is reset to source=direct medium=none unless
  // the current url contains campaign information.
  //
  // Usage:
  //
  //     zaius.anonymize()
  //
  anonymize() {
    tracker.VUID = window['zaius']['VUID'] = tracker.freshVuid();
    tracker.customerId = undefined;
    tracker.zm64_id = undefined;
    utils.setCookie('z_customer_id', '', -100, '');
    utils.setCookie('vtsrc', '', -100, '');
    utils.setCookie('z_idsyncs', '', -100, '');
    dimensions.trafficSources();
    RecentlyViewedProducts.clearProducts();
  },

  // Fire
  // ----

  // Collects the dimensions and sends the pixel ping
  //
  // * `event` the event to send
  //
  fire(event) {
    if (!this.initialized) return Promise.reject();

    // handle customer_id in the event params
    if (dimensions.hasCustomerId(event)) {
      tracker.setOrUpdateCustomerId(event['customer_id']);
    }

    // The idempotence_id is used to avoid duplicate events being observed by real-time segments definition.
    // For more details please see: https://docs.developers.optimizely.com/optimizely-data-platform/docs/realtimesegments-querying-real-time-segments
    event.idempotence_id = utils.generateUUID();

    if (event.event_type) {
      // Store recent events for realtime segments evaluation
      RealtimeSegmentsProxy.addRecentEvent(event);
    }

    if (event.event_type === 'pageview') {
      for (const callback of this.pageviewCallbacks) {
        try {
          callback();
        } catch (e) {
          utils.console.error(e);
        }
      }
    }

    if (event.event_type === 'product' && event.action === 'detail') {
      RecentlyViewedProducts.updateProductView(event.product_id)
    }

    // If SDK is configured to send a special Identity event
    // we assume zm64_id was handled here we avoid sending 2 high-confidence identifiers in
    // same event to avoid merging 2 profiles
    // Read about identifier confidence and resolution https://support.optimizely.com/hc/en-us/articles/8102112179085-Customer-identity-and-resolution
    if (!tracker.config['emitIdentityEvent'] && !event.zm64_id && tracker.zm64_id) {
      event.zm64_id = tracker.zm64_id;
    }

    return this.send(event);
  },

  // Dispatch
  // --------

  // Call **dispatch** to use a Zaius plugin
  //
  // * `pluginName` name of the plugin to use
  // * `pluginFuncName` name of the function to call in the plugin
  // * `args...` arguments to send to the plugin function
  //
  dispatch() {
    const args = Array.prototype.slice.call(arguments);
    let plugin,
      pluginFunc,
      pluginName = args.shift(),
      pluginFuncName = args.shift(),
      pluginNameParts,
      i;

    if (!pluginName || typeof pluginName !== 'string') {
      utils.console.warn('Plugin name argument is missing or not a string:', pluginName);
      return false;
    }
    if (!pluginFuncName) {
      utils.console.warn('Plugin function name argument is missing');
      return false;
    }

    pluginNameParts = pluginName.split('.');
    plugin = tracker[pluginNameParts[0]] || window['zaius'][pluginNameParts[0]];
    for (i = 1; i < pluginNameParts.length; i++) {
      if (!plugin) {
        utils.console.warn('Plugin is missing:', pluginName);
        return false;
      }
      plugin = plugin[pluginNameParts[i]];
    }
    if (plugin) {
      pluginFunc = plugin[pluginFuncName];
      if (pluginFunc && typeof pluginFunc === 'function') {
        pluginFunc.apply(plugin, args);
        return true;
      }
      else {
        utils.console.warn('Plugin function argument is missing or not a function:', pluginName, pluginFuncName);
        return false;
      }
    }
    else {
      utils.console.warn('Plugin is missing:', pluginName);
      return false;
    }
  },

  // Required Fields
  // ---------------

  // Return object containing required fields
  //
  // * `eventType` the event type being tracked.
  //
  requiredFields(eventType) {
    return utils.compact({
      zaius_js_version: ZAIUS_JS_VERSION,
      tracker_id: this.trackerId,
      event_type: eventType
    });
  },

  // Tracking
  // --------

  // Get the tracking related fields.
  //
  tracking() {
    const newUser = this.newUser && this.firstPageView;
    delete this.firstPageView;
    const identifiers = {
      vuid: this.VUID,
      customer_id: tracker.customerId,
      new_user: newUser ? 1 : 0
    };
    const webPushToken = this.getWebPushToken();
    if (webPushToken) {
      const field = webPushToken.tokenField || (webPushToken.appId + '_' + webPushToken.platform + '_push_tokens');
      identifiers[field] = webPushToken.token;
    }
    return utils.compact(identifiers);
  },

  // Visitor UUID
  // -----------

  // Get or (re)set the unique visitor uuid from the `vuid` cookie.  This
  // Cookie value contains the generated uuid and the timestamp of the
  // last page view request.  If a `vuid` query string param is detected the
  // uuid of the `vuid` cookie will be set to the value of the param.
  //
  vuid() {
    const cookieValue = this.parsePipedLastVisitCookie(this.getDeduplicatedCookie('vuid'));
    let uuid = cookieValue[0];
    const param = utils.param('zaiusVuid');
    const visit = '' + new Date().getTime();
    const expires = this.config['visitorCookieExpiryDays'];
    const domain = this.config['cookieDomain'];
    dimensions.lastVisit = dimensions.lastVisit ? dimensions.lastVisit : cookieValue[1];

    if (param && utils.isAscii(param)) uuid = param;
    if (!uuid || !utils.isAscii(uuid)) uuid = utils.generateUUID();

    utils.setCookie('vuid', uuid + '|' + visit, expires, domain);

    return uuid;
  },

  // Get or reset customer_id cookie
  getOrRefreshCustomerId() {
    const cookieValue = this.parsePipedLastVisitCookie(this.getDeduplicatedCookie('z_customer_id'));
    const customer_id = cookieValue[0];
    const visit = '' + new Date().getTime();
    const expires = this.config['visitorCookieExpiryDays'];
    const domain = this.config['cookieDomain'];
    dimensions.lastVisit = dimensions.lastVisit ? dimensions.lastVisit : cookieValue[1];

    if (customer_id !== undefined)
      utils.setCookie('z_customer_id', customer_id + '|' + visit, expires, domain);

    return customer_id;
  },

  setOrUpdateCustomerId(customer_id) {
    // use the vuid cookie for a rough estimate for lastVisit as it is difficult to
    // do this same type of tracking across multiple customer_ids
    const cookieValue = this.parsePipedLastVisitCookie(this.getDeduplicatedCookie('vuid'));
    const visit = '' + new Date().getTime();
    const expires = this.config['visitorCookieExpiryDays'];
    const domain = this.config['cookieDomain'];
    dimensions.lastVisit = dimensions.lastVisit ? dimensions.lastVisit : cookieValue[1];

    if (!customer_id || customer_id === 'undefined' || customer_id === 'false') {
      return;
    }

    if (tracker.customerId !== undefined && tracker.customerId != customer_id.toString()) {
      tracker.VUID = window['zaius']['VUID'] = tracker.freshVuid();
    }
    tracker.customerId = customer_id.toString();

    utils.setCookie('z_customer_id', customer_id + '|' + visit, expires, domain);

    return customer_id;
  },

  // Extract the email from any zm64_id and clear it from the address bar
  // This should prevent copying and sharing a zm64_id value between end users
  getZm64Id() {
    let zm64_id = utils.param('zm64_id');
    let qsParams = utils.windowSearch().substr(1).split('&').filter(qsParam => qsParam.indexOf('zm64_id=') !== 0).join('&');
    let hashParams = utils.windowHash();

    if (zm64_id && window.history && window.history.replaceState) {
      qsParams = qsParams ? '?' + qsParams : '';
      window.history.replaceState(
        window.history.state,
        window.location.title,
        utils.windowPathname() + qsParams + hashParams
      );
    }

    return zm64_id;
  },

  getEmail() {
    try {
      if (this.zm64_id != null) {
        return this.zm64_id && atob(this.zm64_id) || undefined;
      }
    } catch (e) {
      utils.console.error(e);
    }
  },

  // Get a new VUID because customer_id has changed
  freshVuid() {
    const cookieValue = this.parsePipedLastVisitCookie(this.getDeduplicatedCookie('vuid'));
    const visit = '' + new Date().getTime();
    const expires = this.config['visitorCookieExpiryDays'];
    const domain = this.config['cookieDomain'];
    const uuid = utils.generateUUID();

    dimensions.lastVisit = dimensions.lastVisit ? dimensions.lastVisit : cookieValue[1];

    utils.setCookie('vuid', uuid + '|' + visit, expires, domain);

    return uuid;
  },

  parsePipedLastVisitCookie(value) {
    let vuid = undefined;
    let visit = 0;
    if (value) {
      try {
        const parts = value.split('|');
        vuid = parts[0];
        visit = parts[1];
      } catch (e) {}
    }
    return [vuid, visit];
  },

  // name - [string] cookie name
  getDeduplicatedCookie(name) {
    let i, x, y;
    const cookies = document.cookie.split(';');
    const vals = [];
    for (i = 0; i < cookies.length; i++) {
      x = cookies[i].substr(0, cookies[i].indexOf('='));
      y = cookies[i].substr(cookies[i].indexOf('=') + 1);
      x = x.replace(/^\s+|\s+$/g, '');
      if (x == name) {
        try {
          vals.push(decodeURIComponent(y));
        } catch (e) {
          vals.push(unescape(y));
        }
      }
    }
    // deduplicate
    if (vals.length > 1) {
      utils.setCookie(name, '', -100, window.location.hostname);
      utils.setCookie(name, '', -100, '');
    }
    return vals.length > 0 ? vals[vals.length - 1] : undefined;
  },

  // Send
  // ------

  // Send the pixel ping.
  //
  // * `params` the params to send.
  //
  send(params) {
    const image = new Image(1, 1);
    let qs = utils.queryStringify(params);

    const promise = new Promise((resolve, reject) => {
      image.onload = () => {
        resolve();
      };
      image.onerror = () => {
        reject();
      };
    });

    image.src = encodeURI(this.trackerUrl + '/zaius.gif?') + qs;
    return promise;
  },

  // Send IDSync
  // --------

  // Send a pixel ping to an arbitrary 3rd party
  //
  // * `url` an unescaped url for a 1x1 pixel fire
  //
  sendThirdParty(url) {
    const image = new Image(1, 1);
    image.src = encodeURI(url);
  },

  // New user
  // --------

  // Determine if this is the first visit for this user.  Simply check if
  // the vuid cookie is set.
  //
  isNewUser() {
    return !utils.getCookie('vuid');
  },

  // Process Queued
  // -------------------

  // Determine if there are queued invocations that might have been
  // made if this script was loaded asynchronously.
  //
  processQueued() {
    const onloadHandlers = [];
    if (Array.isArray(window['zaius'])) {
      const queued = window['zaius'];
      for (let i = 0; i < queued.length; i++) {
        if (queued[i][0] === 'initialize') {
          const initializeCall = queued[i];
          queued.splice(i, 1);
          queued.unshift(initializeCall);
          break;
        }
      }
      for (let i = 0; i < queued.length; i++) {
        try {
          const method = queued[i].shift();
          if (method === 'initialize') {
            this.initialize.apply(this, queued[i]);
          } else if (method === 'onload') {
            onloadHandlers.push(queued[i]);
          } else if (method === 'customer') {
            this.customer.apply(this, queued[i]);
          } else if (method === 'entity') {
            this.entity.apply(this, queued[i]);
          } else if (method === 'subscribe') {
            this.subscribe.apply(this, queued[i]);
          } else if (method === 'unsubscribe') {
            this.unsubscribe.apply(this, queued[i]);
          } else if (method === 'consent') {
            this.consent.apply(this, queued[i]);
          } else if (method === 'event') {
            this.event.apply(this, queued[i]);
          } else if (method === 'identify') {
            this.identify.apply(this, queued[i]);
          } else if (method === 'anonymize') {
            this.anonymize.apply(this, queued[i]);
          } else if (method === 'dispatch') {
            this.dispatch.apply(this, queued[i]);
          }
        } catch (e) {
          utils.console.error(`${e}\n${e.stack}`);
        }
      }
    }
    return onloadHandlers;
  },

  // Migrate data
  // ----

  // Migrate is run to migrate any data in cookies between versions
  migrate() {
    let version = utils.getCookie('zaius_js_version');
    const expires = this.config['visitorCookieExpiryDays'];
    const domain = this.config['cookieDomain'];

    // possibly a < v2 client, which set jumbe_version cookie.  Use that version and clear the cookie.
    if (version === undefined) {
      version = utils.getCookie('jumbe_version');
      utils.setCookie('jumbe_version', '', -100, '');
    }

    // migration #1 remove vtsrc if set with pre-0.1.8 zaius JS client
    if (version === undefined || utils.versionLessThan(version, '0.1.8')) {
      utils.setCookie('vtsrc', '', -100, domain);
    }

    // migration #2 unset customer_id and generate a new vuid if customer_id
    // is "undefined"
    if (version === undefined || utils.versionLessThan(version, '0.1.9')) {
      const customer_id = this.getOrRefreshCustomerId();
      if (customer_id === 'undefined') {
        utils.setCookie('z_customer_id', '', -100, '');
        this.freshVuid();
      }
    }

    utils.setCookie('zaius_js_version', ZAIUS_JS_VERSION, expires, domain);
  },

  // Synchronize vuid with 3rd parties
  // -----

  // synchronizeVuid is run during Zaius JS initialize to sync with partners when required.
  // Each partner may have a different best practice for when to sync.
  // Maintain time with last sync for a vendor even if they aren't present in the idSyncs
  //
  synchronizeVuid(idSyncs) {
    const now = Math.round(new Date().getTime() / 1000);
    const expires = this.config['visitorCookieExpiryDays'];
    const domain = this.config['cookieDomain'];
    const previousIdSyncs = this.deserializeIdSyncs(utils.getCookie('z_idsyncs'));
    utils.forIn(idSyncs, function (vendor, details) {
      const prevIdSync = previousIdSyncs[vendor];
      if (prevIdSync && (now - prevIdSync['lastSynced'] >= details['ttl'])) {
        tracker.sendThirdParty(tracker.buildIdSyncUrl(details));
        prevIdSync['lastSynced'] = now;
      } else if (prevIdSync === undefined) {
        tracker.sendThirdParty(tracker.buildIdSyncUrl(details));
        previousIdSyncs[vendor] = {lastSynced: now};
      }
    });
    utils.setCookie(
      'z_idsyncs',
      this.serializeIdSyncs(previousIdSyncs),
      expires,
      domain
    );
  },

  // Fire Identity Event
  // -----

  // fires a special Identity event {event_type: "identity"}
  // with at most 2 identifiers - VUID and potentially zm64_id
  // because this event does not include any other high confidence identifiers except zm64_id it won't
  // cause profile merge. The VUID will migrate between profiles relying on eventual consistency.
  //
  fireIdentityEvent() {
    const identityEvent = {
      zm64_id: this.zm64_id,
      vuid: this.VUID,
    };
    const requiredFields = this.requiredFields('identity');
    const dims = dimensions.collect();
    return this.fire(utils.deepClone(utils.mixin(dims, requiredFields, this.dataSourceFields, identityEvent)));
  },

  // Build IDSync url

  // * `idSync` - {url: 'http://third.party.com/idsync?partner_id=${VUID}',
  //               vuidMacro: '${VUID}', ttl: 86400}
  //
  buildIdSyncUrl(idSync) {
    const vuid = this.VUID.replace(/-/g, '');
    //http://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
    function escapeRegExp(str) {
      return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    }
    const vuidReplaceRegex = new RegExp(escapeRegExp(idSync['vuidMacro']), 'g');
    return idSync['url'].replace(vuidReplaceRegex, vuid);
  },

  deserializeIdSyncs(idSyncStr) {
    const parsedEvents = {};
    if (idSyncStr) {
      const events = idSyncStr.split('\n');
      for (let i = 0; i < events.length; i++) {
        const fields = events[i].split('\t');
        parsedEvents[fields[0]] = {lastSynced: parseInt(fields[1], 10)};
      }
    }
    return parsedEvents;
  },

  serializeIdSyncs(vendors) {
    let idSyncsStr = '';
    utils.forIn(vendors, function (vendor, details) {
      idSyncsStr += (vendor + '\t' + details['lastSynced'] + '\n');
    });
    return idSyncsStr.substring(0, idSyncsStr.length - 1);
  },

  autoInitWebPush(pushConfigs) {
    webPush.baseConfigs = pushConfigs;
    let found = false;
    for (const key in pushConfigs) {
      if (pushConfigs.hasOwnProperty(key)) {
        const pushConfig = pushConfigs[key];
        if (window.location.href.indexOf(pushConfig.url) === 0) {
          if (pushConfig.autoInit) {
            found = true;
            webPush.initialize(pushConfig);
          }
          break;
        }
      }
    }
    if (!found) {
      webPush.remove();
    }
  },

  updateWebPushToken(platform, appId, token, tokenField) {
    const pushToken = this.getWebPushToken();
    if (pushToken && pushToken.platform == platform && pushToken.token && pushToken.token != token) {
      // send remove token event
      const event = {
        action: 'remove_push_token',
        app_id: pushToken.appId
      };
      const tokenFieldName = pushToken.tokenField || (pushToken.appId + '_' + pushToken.platform + '_push_tokens');
      event[tokenFieldName] = pushToken.token;
      zaius.event('push', event);
    }
    this.setWebPushToken(platform, appId, token, tokenField);
  },

  // returns {platform: <platform>, appId: <app_id>, token: <token>} or null
  getWebPushToken() {
    const line = (utils.getCookie('zaius_web_push') || '').split("\n")[0];
    const matches = line.match(/([^:]+):([^:]+):([^:]+)(?::(.+))?/);
    if (matches) {
      return {
        platform: matches[1],
        appId: matches[2],
        token: matches[3],
        tokenField: matches[4]
      }
    }
    return null;
  },

  setWebPushToken(platform, appId, token, tokenField) {
    if (token == null) {
      utils.setCookie('zaius_web_push', '', 180)
    } else {
      utils.setCookie('zaius_web_push', platform + ':' + appId + ':' + token + ':' + (tokenField || ''), 180);
    }
  },

  webPush,

  web: WebContent
};

// Test Mode
// ---------

// Expose all the internal objs in the global scope while in test mode.
//
if (SDK_TEST_MODE) {
  window['tracker'] = tracker;
  window['utils'] = utils;
  window['dimensions'] = dimensions;
  window['search'] = search;
  window['DEFAULT_CONFIG'] = DEFAULT_CONFIG;
}

export default tracker;
