import {tracker} from '../tracker';
import {utils} from '../utils';
import {RequestOptions, getJson} from './httpRequest';
import {EventAction, ZaiusEventData} from '../Zaius';
import {ContentManagerBase} from './ContentManagerBase';
import {WebContentInterface} from './WebContentInterface';
import {ContentSection, ZedWebData} from './ZedWebData';
import {LIST_SUBSCRIPTION_DATA_SCHEMA} from './ListSubscriptionData';
import {CUSTOMER_PROFILE_REQUEST_SCHEMA} from './CustomerProfileData';
import {validateData} from '../lib/ValidateData';

export class LandingPageContentManager extends ContentManagerBase {
  private whichContent: ContentSection;
  private messageInterface: WebContentInterface;
  protected readonly contentWindow = window;

  public constructor(protected webData: ZedWebData) {
    super();
    this.whichContent = ContentSection.Main;

    if (this.webData.script) {
      const scriptEl = document.createElement('script');
      scriptEl.innerHTML = this.webData.script;
      document.head.insertBefore(scriptEl, document.head.children.item(0));
    }

    this.messageInterface = this.initializeMessaging(window);
    this.frameLoad.then(() => {
        this.fetchListSubscriptions();
        this.fetchProfileFields();
      }
    );
  }

  public fireWebEvent(type: string, data: ZaiusEventData): Promise<void> {
    // never enrich landing page or preference center events
    // they should use the source and utm params signifying how the user got here
    if (data.action === EventAction.Unsubscribe) {
      data.campaign_id = utils.param('utm_campaign_id')!;
      data.touchpoint_id = utils.param('utm_touchpoint_id')!;
      data.campaign_schedule_run_ts = utils.param('utm_job_id')!;
      data.content_id = utils.param('utm_content_id')!;
    }

    return super.fireWebEvent(type, data, false);
  }

  public contentReady() {
    this.contentWindow.document.body.style.display = '';
  }

  public reportError(message: string) {
    utils.console.error(`[Landing Page] ${message}`);
  }

  public showConfirmation(): void {
    this.whichContent = ContentSection.Confirmation;
    this.contentWindow!.document.open();
    this.contentWindow!.document.write(this.webData.confirmation || 'Missing Content');
    this.contentWindow!.document.close();
    if (this.webData.script) {
      const scriptEl = this.contentWindow.document.createElement('script');
      scriptEl.innerHTML = this.webData.script;
      this.contentWindow!.document.head.insertBefore(
        scriptEl,
        this.contentWindow!.document.head.children.item(0),
      );
    }
    this.initializeMessaging(this.contentWindow);
  }

  private initializeMessaging(targetWindow: Window): WebContentInterface {
    const messageInterface = new WebContentInterface(this, targetWindow);
    this.getRenderContextData().then(({gridData, staticContext}) => {
      // utils.mixin isn't well enough typed to avoid the any here.
      let variables: any = {};
      if (gridData) {
        gridData.forEach((grid) => {
          variables[`__grid_${grid.grid_id}`] = grid.data;
        });
      }
      variables = utils.mixin(variables, staticContext);
      const payload = {
        user: {email: tracker.getEmail()},
        config: this.webData.config,
        render_context: {
          grid_data: gridData,
          static_context: variables,
          variables
        }
      };
      messageInterface.initialize(payload);
      if (this.whichContent === ContentSection.Main) {
        this.recordImpression();
      }
      this.frameLoaded();
      }
    );
    return messageInterface;
  }

  public afterOptOut() {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', OPT_OUT_DESIGN_URL, true);
    xhr.responseType = 'text';
    xhr.onload = () => {
      const status = xhr.status;
      if (status >= 200 && status < 300) {
        const response = xhr.response;
        document.open();
        document.write(response);
        document.close();
      } else {
        window.location.assign(OPT_OUT_DESIGN_URL);
        this.reportError(`Unable to load the opt out confirmation content. Reason: ${xhr.statusText}`);
      }
    };
    xhr.send();
  }

  /** Invokes public Zaius API to fetch list subscriptions.  */
  private fetchListSubscriptions() {
    const zm64_id = tracker.zm64_id;
    const zek = utils.param('zek');
    if (!zm64_id || !zek) {
      // Only fetch the list subscriptions if we have a zek and zm64_id
      return;
    }

    const qs: string = utils.queryStringify({
      zm64_id,
      zek
    });

    const requestOptions: RequestOptions = {
      url: `${tracker.publicApiUrl}/lists/subscriptions?${qs}`,
      headers: [{
        key: 'x-api-key',
        value: tracker.trackerId as any
      }]
    };
    getJson(requestOptions, (subscriptionData: unknown) => {
      try {
        if (validateData(subscriptionData, LIST_SUBSCRIPTION_DATA_SCHEMA)) {
          this.messageInterface.sendListSubscriptionData('load_list_subscriptions', subscriptionData);
        }
      } catch (error) {
        return this.reportError(`Failed to load list subscription content: ${error.message}`);
      }
    }, this.reportError);
  }

  /** Invokes public Zaius API to fetch customer profile fields.  */
  private fetchProfileFields() {
    const zm64_id = tracker.zm64_id;
    const zek = utils.param('zek');
    if (!zm64_id || !zek) {
      // Only fetch the fields if we have a zek and zm64_id
      return;
    }

    const qs: string = utils.queryStringify({
      zm64_id,
      zek
    });

    const requestOptions: RequestOptions = {
      url: `${tracker.publicApiUrl}/profiles?${qs}`,
      headers: [{
        key: 'x-api-key',
        value: tracker.trackerId as any
      }]
    };
    getJson(requestOptions, (profileData: unknown) => {
      try {
        if (validateData(profileData, CUSTOMER_PROFILE_REQUEST_SCHEMA)) {
          this.messageInterface.sendCustomerProfileData('load_profile_data', profileData.attributes);
        }
      } catch (error) {
        return this.reportError(`Failed to load customer profile content: ${error.message}`);
      }
    }, this.reportError);
  }

}
